"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeLoopPeriod = exports.makeStartEnvelope = void 0;
const core_utils_1 = require("@brc/core-utils");
const lodash_1 = require("lodash");
function makeStartEnvelope(args, strategy) {
    const maxPurchases = Math.max((0, lodash_1.get)(args.data, 'config.maxPurchases', 1), strategy?.minPurchases || 1);
    const minEnvelope = (0, core_utils_1.fixFloat)(args.quote.config.envelope * maxPurchases);
    const quoteAsset = args.assets.filter((asset) => asset.currency === args.quote.id)[0];
    const maxEnvelope = quoteAsset ? quoteAsset.available : 0;
    return {
        path: 'config.startEnvelope',
        label: `Envelope [ ${minEnvelope} , ${maxEnvelope} ]`,
        type: 'float',
        limit: [minEnvelope, maxEnvelope],
        default: minEnvelope,
        required: true,
        unit: args.quote.id,
    };
}
exports.makeStartEnvelope = makeStartEnvelope;
function makeLoopPeriod(config) {
    return {
        path: 'config.loopPeriod',
        label: 'Durée des cycles',
        type: 'range',
        limit: [1, 10],
        step: 1,
        default: config?.loopPeriod || 2,
        required: true,
        unit: 'sec.',
    };
}
exports.makeLoopPeriod = makeLoopPeriod;
