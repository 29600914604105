"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(rounds) {
    const actions = [];
    for (const [time, round] of rounds.entries()) {
        const input = round.actions?.[0];
        if (!input) {
            continue;
        }
        actions.push({
            time,
            type: input.type,
        });
    }
    return actions;
}
exports.default = default_1;
