"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(data, builder) {
    for (const item of data.model.patterns) {
        builder.push({
            key: item.key,
            type: 'res',
            name: item.name,
            info: item.info,
            format: 'boolean',
            values: data.rounds.map((round) => round.candle.patterns.includes(item.key)),
            access: `main.candle.${item.key}.get()`,
        });
    }
}
exports.default = default_1;
