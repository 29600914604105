"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const dealsDefault = 10;
const dealsLimit = 25;
function default_1(args) {
    return [
        [
            {
                path: 'config.loopPeriod',
                label: 'Durée des rounds',
                type: 'range',
                limit: [1, 10],
                step: 0.5,
                default: 2,
                unit: 'sec.',
            },
        ],
        [
            {
                path: 'config.manyPurchases',
                label: 'Achats multiples',
                type: 'switch',
            },
            {
                path: 'config.minPurchases',
                label: 'Achats min',
                type: 'integer',
                limit: [1, (0, lodash_1.get)(args.data, 'config.maxPurchases', dealsLimit + 1) - 1],
                default: 2,
                disabled: !(0, lodash_1.get)(args.data, 'config.manyPurchases'),
            },
            {
                path: 'config.maxPurchases',
                label: 'Achats max',
                type: 'integer',
                limit: [(0, lodash_1.get)(args.data, 'config.minPurchases', 0) + 1, dealsLimit],
                default: dealsDefault,
                disabled: !(0, lodash_1.get)(args.data, 'config.manyPurchases'),
            },
        ],
        [
            {
                path: 'config.manySales',
                label: 'Ventes multiples',
                type: 'switch',
            },
            {
                path: 'config.minSales',
                label: 'Ventes min',
                type: 'integer',
                limit: [1, (0, lodash_1.get)(args.data, 'config.maxSales', dealsLimit + 1) - 1],
                default: 2,
                disabled: !(0, lodash_1.get)(args.data, 'config.manySales'),
            },
            {
                path: 'config.maxSales',
                label: 'Ventes max',
                type: 'integer',
                limit: [(0, lodash_1.get)(args.data, 'config.minSales', 0) + 1, dealsLimit],
                default: dealsDefault,
                disabled: !(0, lodash_1.get)(args.data, 'config.manySales'),
            },
        ],
    ];
}
exports.default = default_1;
