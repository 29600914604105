"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(logger, port) {
    return {
        list(model, query, listener) {
            return port.observe({ type: 'model.list', model, query }, (feedback) => {
                if (feedback.type === 'model.listed') {
                    logger.debug('model.watcher.listed', { model, data: feedback.data });
                    listener(feedback.data);
                }
            });
        },
        read(model, id, listener) {
            return port.observe({ type: 'model.read', model, id }, (feedback) => {
                if (feedback.type === 'model.entity') {
                    logger.debug('model.watcher.read', { model, data: feedback.data });
                    listener(feedback.data);
                }
            });
        },
        watch(model, listener) {
            return port.observe({ type: 'model.watch', model }, (feedback) => {
                if (feedback.type === 'model.stats') {
                    logger.debug('model.watcher.stats', { model, data: feedback.data });
                    listener(feedback.data);
                }
            });
        },
    };
}
exports.default = default_1;
