"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const range_1 = require("../range");
function default_1(vars, unit) {
    if (unit === 'percent') {
        return { min: 0, max: 100 };
    }
    if (unit === 'ratio') {
        return { min: 0, max: 1 };
    }
    const min = [];
    const max = [];
    for (const item of vars) {
        const values = item.values.filter((value) => value > 0);
        min.push(Math.min(...values));
        max.push(Math.max(...values));
    }
    return (0, range_1.makeRange)({ min: Math.min(...min), max: Math.max(...max) });
}
exports.default = default_1;
