"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(type, path) {
    let completions = [
        { value: `${path}.has()`, meta: 'function' },
        { value: `${path}.get()`, meta: 'function' },
        { value: `${path}.list()`, meta: 'function' },
        { value: `${path}.count()`, meta: 'function' },
        { value: `${path}.getLimit()`, meta: 'function' },
        { value: `${path}.setLimit()`, meta: 'function' },
    ];
    if (['read', 'write'].includes(type.level)) {
        completions = completions.concat([
            { value: `${path}.getPeriod()`, meta: 'function' },
            { value: `${path}.startPeriod()`, meta: 'function' },
            { value: `${path}.endPeriod()`, meta: 'function' },
        ]);
    }
    if (['write'].includes(type.level)) {
        completions = completions.concat([
            { value: `${path}.set()`, meta: 'function' },
            { value: `${path}.setPeriod()`, meta: 'function' },
            { value: `${path}.reset()`, meta: 'function' },
        ]);
    }
    return completions;
}
exports.default = default_1;
