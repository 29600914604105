"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return {
        name: 'Arc sinus',
        info: "Retourne l'arc sinus d'un nombre",
        args: [{ key: 'value', name: 'Nombre en entrée', type: 'number' }],
        result: { type: 'number' },
        call(value) {
            return Math.asin(value) || 0;
        },
    };
}
exports.default = default_1;
