"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makePatterns = exports.makeIndicators = exports.makeDeciders = void 0;
const lodash_1 = require("lodash");
const base_1 = require("./base");
function makeDeciders(graphs) {
    return graphs.map((child) => ({
        key: child.strategy.key,
        name: child.strategy.name,
        info: child.strategy.info,
        vars: (0, base_1.makeVars)(child.parameters || []),
        deps: (0, base_1.makeDeps)(child.computers || []),
    }));
}
exports.makeDeciders = makeDeciders;
function makeIndicators(graphs) {
    return graphs.map((child) => ({
        key: child.strategy.key,
        name: child.strategy.name,
        info: child.strategy.info,
        vars: (0, base_1.makeVars)(child.parameters || []),
        deps: (0, base_1.makeDeps)(child.computers || []),
        unit: (0, lodash_1.get)(child.strategy, 'config.graph.unit', 'other'),
        zone: (0, lodash_1.get)(child.strategy, 'config.graph.zone', 'none'),
        color: (0, lodash_1.get)(child.strategy, 'config.graph.color'),
        stroke: (0, lodash_1.get)(child.strategy, 'config.graph.stroke'),
    }));
}
exports.makeIndicators = makeIndicators;
function makePatterns(graphs) {
    return graphs.map((child) => ({
        key: child.strategy.key,
        name: child.strategy.name,
        info: child.strategy.info,
    }));
}
exports.makePatterns = makePatterns;
