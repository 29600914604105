"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
async function default_1(logger, port, strategy, code) {
    const feedback = await port.execute({ type: 'strategy.check', strategy, code });
    if (feedback.type !== 'strategy.checked' || feedback.strategy !== strategy) {
        logger.alert('strategy.check.invalid', { query: { strategy, code }, feedback });
        throw new Error(`Invalid feedback for strategy check.`);
    }
    const check = feedback.check;
    return check.status === 'error' ? check.errors : [];
}
exports.default = default_1;
