"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const hooks_1 = require("./hooks");
function Component(props) {
    const round = (0, hooks_1.useSessionRound)(props.session);
    if (!round) {
        return props.render();
    }
    return props.render(round);
}
Component.displayName = 'store.session.round';
exports.default = Component;
