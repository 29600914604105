"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeSeconds = void 0;
const float_1 = require("./float");
const units = {
    second: 1,
    seconds: 1,
    minute: 60,
    minutes: 60,
    hour: 3600,
    hours: 3600,
    day: 86400,
    days: 86400,
};
function makeSeconds(value, unit) {
    const factor = units[unit];
    if (!factor) {
        throw new Error(`Invalid time unit: ${unit}.`);
    }
    return (0, float_1.fixFloat)(value * factor);
}
exports.makeSeconds = makeSeconds;
