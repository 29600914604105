"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const field_1 = require("./field");
const form_1 = require("./form");
function default_1(form) {
    const memory = { specs: {}, states: {} };
    const refresh = () => form.onChange((0, form_1.resolve)(form, memory), (failures) => {
        (0, form_1.fail)(form, memory, failures);
        refresh();
    });
    (0, form_1.render)(form, memory);
    for (const path of Object.keys(form.changes || {})) {
        (0, form_1.handle)(form, memory, path, form.changes[path]);
    }
    refresh();
    return {
        render() {
            return (0, form_1.render)(form, memory);
        },
        read(path) {
            if (!memory.specs[path]) {
                throw new Error(`Tried to read unknown form field "${path}".`);
            }
            const specs = memory.specs[path];
            const state = memory.states[path] || { input: undefined, status: 'same' };
            const input = state.input === undefined ? (0, field_1.format)(specs, (0, lodash_1.get)(form.data, path, specs.default)) : state.input;
            return Object.assign({}, state, { input });
        },
        handle(path, input) {
            if (!memory.specs[path]) {
                throw new Error(`Tried to handle unknown form field "${path}".`);
            }
            (0, form_1.handle)(form, memory, path, input);
            // trick to handle function fields
            (0, form_1.render)(form, memory);
            (0, form_1.handle)(form, memory, path, input);
            refresh();
        },
    };
}
exports.default = default_1;
