"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(config, report) {
    const profit = report.profit;
    const currency = config.startCurrency;
    const percent = profit.percent;
    const balance = report.balance?.[currency] || { start: 0, injections: 0, deals: 0 };
    return {
        label: 'Profit',
        color: percent < 0 ? 'error' : undefined,
        value: { type: 'number.percent', data: percent },
        body: [
            {
                title: 'Enveloppe',
                props: [
                    { label: 'Départ', type: 'number.crypto', unit: currency, value: config.startEnvelope },
                    { label: 'Actuelle', type: 'number.crypto', unit: currency, value: balance.start },
                    { label: 'Injecté', type: 'number.crypto', unit: currency, value: balance.injections },
                ],
            },
            {
                title: `Gains`,
                props: [
                    { label: 'Total', type: 'number.crypto', unit: currency, value: profit.volume },
                    { label: 'Profit', type: 'number.percent', value: profit.percent },
                    { label: 'Réinvesti', type: 'number.crypto', unit: currency, value: Math.max(0, profit.snowball) },
                    { label: 'Réserve', type: 'number.crypto', unit: currency, value: profit.reserve },
                    { label: 'Trésor', type: 'number.crypto', unit: currency, value: profit.treasure },
                ],
            },
        ],
    };
}
exports.default = default_1;
