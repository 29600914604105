"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeWalletTreasure = exports.makeWalletReserve = void 0;
function makeWalletReserve() {
    return {
        path: 'config.walletReserve',
        label: `Réserve`,
        type: 'integer',
        limit: [0, 100],
        default: 15,
        required: true,
        unit: '% gain',
    };
}
exports.makeWalletReserve = makeWalletReserve;
function makeWalletTreasure() {
    return {
        path: 'config.walletTreasure',
        label: `Trésor`,
        type: 'integer',
        limit: [0, 100],
        default: 15,
        required: true,
        unit: '% gain',
    };
}
exports.makeWalletTreasure = makeWalletTreasure;
