"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return [
        {
            label: 'Arguments',
            title: "Arguments",
            code: [
                'type args.feeTaker = number;',
                '// Ratio de commission "taker"',
                '',
                'type args.feeMaker = number;',
                '// Ratio de commission "maker"',
                '',
                'type args.envelope = number;',
                '// Enveloppe demandée',
                '// - en "quote" pour les achats',
                '// - en "base" pour les ventes',
                '',
                'type args.limitPrice = ?number;',
                '// Prix limite pour les ventes',
                '',
                'type args.targetPrice = ?number;',
                '// Prix cible pour les ventes',
                '',
            ],
        },
    ];
}
exports.default = default_1;
