"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const utils_1 = require("./utils");
function default_1(state, env) {
    return {
        assign(index, value) {
            const key = (0, utils_1.makeNatural)(index);
            if (key < state.limit) {
                state.data[key] = (0, utils_1.makeFloat)(value, 0);
            }
        },
        commit() {
            const next = state.data.length ? state.data[0] : env.base;
            state.data.unshift(next);
            if (state.data.length > state.limit) {
                state.data.splice(state.limit, state.data.length - state.limit);
            }
        },
        dump() {
            return state.data.slice();
        },
    };
}
exports.default = default_1;
