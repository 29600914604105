"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vars_1 = require("@brc/core-script/vars");
function default_1(state, values) {
    for (const key of Object.keys(values)) {
        if (!state.indicators[key]) {
            const lib = (0, vars_1.makeLib)({
                path: ['candle'],
                keys: [key],
                getRound: () => state.round,
            });
            state.indicators[key] = lib[key];
        }
        state.indicators[key].set(values[key]);
        state.indicators[key].commit();
    }
    for (const key of Object.keys(state.indicators)) {
        if (!values.hasOwnProperty(key)) {
            delete state.indicators[key];
        }
    }
}
exports.default = default_1;
