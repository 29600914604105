"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function default_1(trades, last) {
    const prices = trades.map((trade) => trade.price);
    const fallback = last ? last.price : 0;
    if (!prices.length) {
        return {
            open: fallback,
            low: fallback,
            high: fallback,
            close: fallback,
            volume: 0,
            trades: 0,
        };
    }
    return {
        open: prices[0],
        low: Math.min(...prices),
        high: Math.max(...prices),
        close: prices[prices.length - 1],
        volume: (0, lodash_1.sum)(trades.map((trade) => trade.volume)),
        trades: trades.length,
    };
}
exports.default = default_1;
