"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeCandles = exports.makeCandleLifetime = exports.makeCandleIndex = exports.makeCandleStamp = void 0;
const core_candle_1 = require("@brc/core-candle");
const lodash_1 = require("lodash");
// returns period in milliseconds
function makeCandlePeriod(period) {
    if (!core_candle_1.periods[period]) {
        throw new Error(`Invalid candle period "${period}".`);
    }
    return core_candle_1.periods[period] * 1000;
}
// returns stamp in milliseconds
function makeCandleStamp(args) {
    return args.index * makeCandlePeriod(args.period);
}
exports.makeCandleStamp = makeCandleStamp;
function makeCandleIndex(args) {
    return Math.floor(args.stamp / makeCandlePeriod(args.period));
}
exports.makeCandleIndex = makeCandleIndex;
// returns ttl in seconds
function makeCandleLifetime(args) {
    const nowIndex = makeCandleIndex({ period: args.period, stamp: new Date().getTime() });
    const limitStamp = makeCandleStamp({ period: args.period, index: nowIndex - args.length });
    const stamp = makeCandleStamp({ period: args.period, index: args.index });
    return Math.ceil(Math.max(stamp - limitStamp, makeCandlePeriod(args.period)) / 1000);
}
exports.makeCandleLifetime = makeCandleLifetime;
function mergeCandles(candles) {
    if (!candles.length) {
        throw new Error(`Tried to merge an empty list of candles.`);
    }
    return {
        open: candles[0].open,
        close: candles[candles.length - 1].close,
        low: Math.min(...candles.map(candle => candle.low)),
        high: Math.min(...candles.map(candle => candle.high)),
        volume: (0, lodash_1.sum)(candles.map(candle => candle.volume)),
        trades: (0, lodash_1.sum)(candles.map(candle => candle.trades || 0)) || undefined,
    };
}
exports.mergeCandles = mergeCandles;
