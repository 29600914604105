"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(platform) {
    const details = platform.details;
    const props = [
        { label: 'Utilisée', value: platform.used, type: 'boolean' },
        { label: 'Symboles', value: details.symbols, type: 'number' },
        { label: 'Volume 24h', value: details.volumes.day, type: 'number.integer', unit: '€' },
        { label: 'Volume 7j', value: details.volumes.week, type: 'number.integer', unit: '€' },
        { label: 'Volume 30j', value: details.volumes.month, type: 'number.integer', unit: '€' },
        { label: 'Change 24h', value: details.changes.day, type: 'number.integer', unit: '%' },
        { label: 'Change 7j', value: details.changes.week, type: 'number.integer', unit: '%' },
        { label: 'Change 30j', value: details.changes.month, type: 'number.integer', unit: '%' },
        { label: 'Liquidité 24h', value: details.liquidity.day, type: 'number.integer' },
    ];
    if (details.launch) {
        props.push({ label: 'Lancement', value: details.launch, type: 'moment.date' });
    }
    return { props, links: details.links || {}, image: details.logo };
}
exports.default = default_1;
