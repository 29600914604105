"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (theme) => ({
    container: (props) => ({
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        fontSize: theme.typography.body1.fontSize.toString(),
        minWidth: '170px',
        lineHeight: 'inherit',
        background: props.paper ? 'transparent' : theme.palette.background.paper,
        borderRadius: props.paper ? theme.shape.borderRadius : `${props.height / 2}px`,
    }),
    icon: (props) => ({
        width: `${props.height - 4}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.text.secondary,
    }),
    placeholder: (props) => ({
        color: theme.palette.text.secondary,
    }),
    clear: (props) => ({
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        lineHeight: 'inherit',
        width: `${props.height - 4}px`,
        cursor: 'pointer',
        borderRadius: props.paper ? theme.shape.borderRadius : `${props.height / 2}px`,
        background: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.secondary.main,
        margin: '2px',
        '&:hover': { background: theme.palette.secondary.background },
    }),
    input: (props) => ({
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        boxSizing: 'border-box',
        outline: 'none',
        color: theme.palette.text.primary,
        background: 'transparent',
        border: '1px solid transparent',
        borderColor: props.paper ? 'transparent' : theme.palette.border.hard,
        borderRadius: props.paper ? theme.shape.borderRadius : `${props.height / 2}px`,
        padding: `0 ${props.height - 4}px`,
        fontSize: theme.typography.body1.fontSize.toString(),
        '&:focus': {
            borderColor: theme.palette.primary.main,
            boxShadow: `inset 0 0 3px ${theme.palette.primary.main}`,
        },
    }),
});
