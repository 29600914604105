"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeStatus = exports.makeHash = void 0;
function makeHash(key) {
    return [key.model, key.id, key.scope].join(':');
}
exports.makeHash = makeHash;
function makeStatus(feedback) {
    if (feedback.type === 'lock.status') {
        return feedback.status;
    }
}
exports.makeStatus = makeStatus;
