"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vars_1 = require("@brc/core-script/vars");
const core_candle_1 = require("@brc/core-candle");
function default_1(model) {
    return function (complete) {
        return model.list('strategy', { type: 'indicator' }, (indicators) => {
            const completions = [];
            for (const key of vars_1.keys.ticker) {
                for (const method of vars_1.keys.base) {
                    completions.push({ value: `main.ticker.${key}.${method}()`, meta: 'function' });
                }
            }
            const candleScopes = ['candle', ...Object.keys(core_candle_1.periods).map((period) => `candle${period}`)];
            const candleKeys = [...vars_1.keys.candle, ...indicators.map((indicator) => indicator.key), ...core_candle_1.trends];
            for (const scope of candleScopes) {
                for (const key of candleKeys) {
                    for (const method of vars_1.keys.base) {
                        completions.push({ value: `main.${scope}.${key}.${method}()`, meta: 'value' });
                    }
                }
            }
            complete(completions);
        });
    };
}
exports.default = default_1;
