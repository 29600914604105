"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(rounds, key) {
    const segments = [];
    const offset = rounds.length ? rounds[0].index : 0;
    let cursor;
    for (const round of rounds) {
        const value = round.candle.indicators[key] || round.vars[key] || 0;
        if (cursor && cursor.value !== value) {
            segments.push({
                length: round.index - cursor.start,
                range: [cursor.start - offset, round.index - 1 - offset],
                value: cursor.value,
            });
            cursor = undefined;
        }
        if (!cursor) {
            cursor = { start: round.index, value };
        }
    }
    if (cursor) {
        const index = rounds[rounds.length - 1].index;
        segments.push({
            length: index - cursor.start,
            range: [cursor.start - offset, index - offset],
            value: cursor.value,
        });
    }
    return segments;
}
exports.default = default_1;
