"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(input) {
    const rounds = [];
    for (const round of input.rounds) {
        rounds.push({
            id: round.index,
            status: round.status || 'pending',
            actions: round.actions,
        });
    }
    return { enabled: input.setup.flags.thread, rounds };
}
exports.default = default_1;
