"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeFlags = exports.makeSetter = exports.makeDefaults = void 0;
function makeDefaults(periods, flags) {
    if (flags) {
        const defaults = makeDefaults(periods);
        return { ...defaults, flags: flags(defaults) };
    }
    return {
        period: Object.keys(periods)[0] || '',
        zoom: 2,
        flags: {
            candle: true,
            ticker: false,
            patterns: false,
            deciders: false,
            thread: false,
            book: false,
            env: false,
            zones: false,
            vars: false,
        },
    };
}
exports.makeDefaults = makeDefaults;
function makeSetter(set, flags) {
    if (flags) {
        return (setup) => set({ ...setup, flags: flags(setup) });
    }
    return set;
}
exports.makeSetter = makeSetter;
function makeFlags(session) {
    return {
        ticker: session,
        candle: true,
        book: session,
        env: session,
        deciders: session,
        thread: session,
        zones: true,
        patterns: true,
        vars: session,
    };
}
exports.makeFlags = makeFlags;
