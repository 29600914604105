"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const utils_1 = require("./utils");
function default_1() {
    return {
        number() {
            return { kind: 'scalar', type: 'number' };
        },
        boolean() {
            return { kind: 'scalar', type: 'boolean' };
        },
        string() {
            return { kind: 'scalar', type: 'string' };
        },
        any() {
            return { kind: 'scalar', type: 'any' };
        },
        enum(values) {
            return { kind: 'enum', values };
        },
        shape(props, readonly) {
            return (0, utils_1.buildShape)(props, readonly);
        },
        array(proto) {
            return { kind: 'array', proto };
        },
        record(proto) {
            return { kind: 'record', proto };
        },
        func(args, result = {}) {
            return { kind: 'func', args, result };
        },
        arg(key, type, optional = false) {
            return { key, type, optional };
        },
        map(keys, type) {
            return (0, utils_1.buildMap)(keys, type);
        },
        vars(level) {
            return { kind: 'vars', level };
        },
    };
}
exports.default = default_1;
