"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const utils_1 = require("./utils");
function default_1(logger, port, auth) {
    return {
        async check(session) {
            await port.execute({ type: 'session.check', session, save: true });
        },
        follow(session, listener) {
            return port.observe({ type: 'session.follow', session }, (feedback) => {
                if (feedback.type === 'session.round' && feedback.session === session) {
                    listener(feedback.round);
                    (0, utils_1.debug)(feedback.round);
                }
            });
        },
        feed(session, listener) {
            let rounds = [];
            function add(added) {
                const next = (0, lodash_1.orderBy)((0, lodash_1.uniqBy)(added.concat(rounds), 'index'), 'index', 'asc');
                if (next.length > rounds.length) {
                    listener(next);
                    rounds = next;
                }
            }
            return port.observe({ type: 'session.follow', session, feed: true }, (feedback) => {
                if (feedback.type === 'session.rounds' && feedback.session === session) {
                    add(feedback.rounds);
                }
                if (feedback.type === 'session.round' && feedback.session === session) {
                    add([feedback.round]);
                    (0, utils_1.debug)(feedback.round);
                }
            });
        },
        async trigger(session, action) {
            await port.execute({ type: 'session.trigger', session, action });
        },
        // todo: add recorder
        async record(creation) {
            const feedback = await port.execute({ type: 'session.record', ...creation });
            if (feedback.type !== 'session.recorder')
                throw 'session';
            return feedback.recorder;
        },
        download(recorder) {
            const url = `${process.env.brc_api}/download?type=recorder&id=${recorder}&token=${auth.read().token}`;
            (0, utils_1.download)(url, 'record.csv');
        },
    };
}
exports.default = default_1;
