"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(data) {
    if (!data) {
        return {
            shape: { open: 0, high: 0, low: 0, close: 0, volume: 0, trades: 0 },
            patterns: [],
            trend: { price: 0, volume: 0 },
            indicators: {},
        };
    }
    return {
        shape: data.shape,
        patterns: data.patterns,
        graph: data.graph,
        trend: data.trend,
        indicators: data.indicators,
    };
}
exports.default = default_1;
