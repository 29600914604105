"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.labels = exports.choices = void 0;
const choices = [
    { label: 'Solide', value: 'solid' },
    { label: 'Tirets longs', value: 'long-dash' },
    { label: 'Tirets courts', value: 'short-dash' },
    { label: 'Tirets / points', value: 'dash-dot' },
    { label: 'Pointillés', value: 'dot' },
];
exports.choices = choices;
const labels = {};
exports.labels = labels;
for (const choice of choices) {
    labels[choice.value] = choice.label;
}
