"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const names = { available: 'Dispo', locks: 'Mobilisé', reserve: 'Réserve', treasure: 'Trésor', total: 'Total' };
function default_1(data, builder) {
    for (const key of Object.keys(names)) {
        builder.push({
            key: `base.${key}`,
            type: 'dat',
            name: `Base : ${names[key]}`,
            format: 'number.crypto',
            values: data.rounds.map(round => round.env?.wallet.base[key]),
            access: `env.wallet.base.${key}`,
        });
    }
    for (const key of Object.keys(names)) {
        builder.push({
            key: `quote.${key}`,
            type: 'dat',
            name: `Quote : ${names[key]}`,
            format: 'number.crypto',
            values: data.rounds.map(round => round.env?.wallet.quote[key]),
            access: `env.wallet.quote.${key}`,
        });
    }
}
exports.default = default_1;
