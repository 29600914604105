"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return [
        {
            label: 'Ordres',
            title: "Méthodes d'achat / vente / annulation",
            code: [
                'main.buy(options: OrderOptions): OrderManager;',
                "// Lance un ordre d'achat",
                '',
                'main.sell(options: OrderOptions): OrderManager;',
                '// Lance un ordre de vente',
                '',
                'main.cancel(): boolean;',
                '// Annule un ordre passé',
                '',
                'main.getUsed(): number;',
                "// Retourne le volume total d'enveloppe utilisé",
                '',
                '// Tous les paramètres sont optionnels',
                'type OrderOptions = {',
                "    envelope?: number,",
                "    // Enveloppe allouée à l'ordre, par défaut ce sera l'enveloppe restante",
                '    pivot?: number,',
                "    // Prix pivot pour les conversions, par défaut ce sera le last price du ticker",
                '    limit?: number,',
                "    // Prix limite pour les ordres ordre limité",
                '    timeout?: number,',
                "    // Temps maximum autorisé en secondes, par défaut ce sera 1 minute",
                '    leverage?: number,',
                "    // Effet de levier (si disponible), par défaut ce sera 0",
                '    timing?: // Option temporelle (si disponible)',
                "        | 'GTC' // Good til cancel (défaut)",
                "        | 'IOC' // Immediate or cancel",
                "        | 'FOK', // Fill or kill",
                '};',
                '',
                'type OrderManager = {',
                "    success: boolean, // Vrai si l'ordre s'est terminé avec succès",
                '    filled: number, // Volume acquis',
                "    used: number, // Volume d'enveloppe utilisé",
                "    cancel(): boolean, // Lance l'annulation de l'ordre",
                "    wait(): Promise<void>, // Attend la résolution de l'ordre",
                '};',
            ],
        },
        {
            label: 'Chainage',
            title: "Chainage d'émission des ordres",
            code: [
                'main.wait(seconds: number): Promise<void>',
                "// Permet d'attendre pendant le nombre de secondes précisé",
                '',
                'main.chain(providers: OrderProvider[]): Promise<void>;',
                '',
                'type OrderProvider = (step: OrderStep) => Promise<OrderOptions | void>;',
                '// Le fournisseur est une fonction asynchrone :',
                "//   - qui renvoie les options de l'ordre à passer",
                "//   - ou rien si l'étape doit être omise.",
                '// Les options renvoyées sont décrites ci-dessus ("Ordres").',
                '',
                'type OrderStep = {',
                '    used: number,',
                "    // Volume total d'enveloppe utilisé",
                '    limit(price: number): number,',
                "    // Calcule l'enveloppe limite en fonction d'un prix",
                '    end(): void,',
                '    // Termine toute la chaine',
                '};',
            ],
        },
        {
            label: 'Marchés',
            title: 'Lecture des données de marché',
            code: [
                'main.getBids(): Promise<BookPoint[]>;',
                '// Lit la liste des "bids" (asynchrone)',
                '',
                'main.getAsks(): Promise<BookPoint[]>;',
                '// Lit la liste des "asks" (asynchrone)',
                '',
                "type BookPoint = { // Position dans l'order book",
                '    price: number, // prix de la position',
                '    volume: number, // volume pour le prix',
                '    total: number, // volume * prix',
                '    percent: number, // volume relatif au total des 20 points',
                '    cumulative: number, // volume cumulé',
                '    wall: boolean, // vrai si le point est un mur',
                '};',
            ],
        },
    ];
}
exports.default = default_1;
