"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildDeps = exports.buildVars = void 0;
function buildVars(data, builder, target, path) {
    for (const item of target.vars) {
        const access = [...path, item.key].join('.');
        builder.push({
            key: `vars.${item.key}`,
            type: 'var',
            name: item.key,
            info: item.info,
            format: 'number',
            values: data.rounds.map((round) => (round.vars || {})[access]),
        });
    }
}
exports.buildVars = buildVars;
function buildDeps(data, builder, target, path) {
    for (const item of target.deps) {
        const dep = builder.enter({ type: 'dep', key: item.key, name: item.key, info: item.info });
        buildVars(data, dep, item, [...path, item.key]);
        buildDeps(data, dep, item, [...path, item.key]);
    }
}
exports.buildDeps = buildDeps;
