"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function default_1(field, input) {
    if (input === null || input === undefined) {
        return field.default;
    }
    switch (field.type) {
        case 'string':
        case 'text':
        case 'key':
        case 'date':
            input = input.trim();
            return input.length ? input : undefined;
        case 'integer':
            input = input.replace(/\s/g, '');
            input = input.length ? parseInt(input) : undefined;
            return isNaN(input) ? undefined : input;
        case 'range':
        case 'float':
            input = input.replace(/\s/g, '').replace(',', '.');
            input = input.length ? parseFloat(input) : undefined;
            return isNaN(input) ? undefined : input;
        case 'radios':
        case 'select':
        case 'color':
            return input;
        case 'checks':
        case 'tags':
            return (0, lodash_1.uniq)(input);
        case 'switch':
            return !!input;
    }
    return undefined;
}
exports.default = default_1;
