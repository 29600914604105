"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function default_1(field, input) {
    if (input === null || input === undefined || input === '') {
        return field.required ? { missing: true, violations: [] } : undefined;
    }
    const min = field.limit ? field.limit[0] : undefined;
    const max = field.limit ? field.limit[1] : undefined;
    switch (field.type) {
        case 'string':
        case 'color':
        case 'text': {
            if (typeof input !== 'string')
                return { violations: ['Doit être une chaîne de caractères.'] };
            if (field.required && !input.trim().length)
                return { missing: true };
            if (min && input.length < min)
                return { violations: [`Trop court : ${min} caractères minimum.`] };
            if (max && input.length > max)
                return { violations: [`Trop long : ${max} caractères maximum.`] };
            return undefined;
        }
        case 'key': {
            if (typeof input !== 'string')
                return { violations: ['Doit être une chaîne de caractères.'] };
            if (field.required && !input.trim().length)
                return { missing: true };
            if (input.match(/^[^a-z]/))
                return { violations: ['Doit commencer par une lettre.'] };
            if (input.match(/[A-Z]/))
                return { violations: ['Ne doit pas comporter de majuscule.'] };
            if (input.match(/\s]/))
                return { violations: ['Ne doit pas comporter de caractère blanc.'] };
            if (!input.match(/^[a-z][a-z0-9_]*$/))
                return { violations: ['Doit être composé de lettres, chiffres et _.'] };
            if (min && input.length < min)
                return { violations: [`Trop court : ${min} caractères minimum.`] };
            if (max && input.length > max)
                return { violations: [`Trop long : ${max} caractères maximum.`] };
            return undefined;
        }
        case 'integer': {
            if (typeof input !== 'string')
                return { violations: ['Doit être une chaîne de caractères.'] };
            input = input.replace(/\s+/g, '');
            if (field.required && !input.length)
                return { missing: true };
            if (!input.match(/^-?[0-9]*$/))
                return { violations: ['Doit être un nombre entier.'] };
            if (min && parseInt(input) < min)
                return { violations: [`Trop petit : ${min} minimum.`] };
            if (max && parseInt(input) > max)
                return { violations: [`Trop grand : ${max} maximum.`] };
            return undefined;
        }
        case 'range':
        case 'float': {
            if (typeof input !== 'string')
                return { violations: ['Doit être une chaîne de caractères.'] };
            input = input.replace(/\s+/g, '');
            if (field.required && !input.length)
                return { missing: true };
            if (!input.match(/^-?[0-9]*(\.|,)?[0-9]*$/))
                return { violations: ['Doit être un nombre décimal.'] };
            if (min && parseFloat(input) < min)
                return { violations: [`Trop petit : ${min} minimum.`] };
            if (max && parseFloat(input) > max)
                return { violations: [`Trop grand : ${max} maximum.`] };
            return undefined;
        }
        case 'radios':
        case 'select': {
            const authorized = (field.choices || []).map(choice => choice.value).includes(input);
            if (!authorized)
                return { violations: ["Doit être l'une des valeurs autorisées"] };
            return undefined;
        }
        case 'tags': {
            if (!Array.isArray(input))
                return { violations: ['Doit être un tableau de valeurs.'] };
            return undefined;
        }
        case 'checks': {
            if (!Array.isArray(input))
                return { violations: ['Doit être un tableau de valeurs.'] };
            const authorized = (field.choices || []).map(choice => choice.value);
            const unauthorized = (0, lodash_1.difference)(input, authorized);
            if (unauthorized.length)
                return { violations: ['Doit être des valeurs autorisées'] };
            return undefined;
        }
        case 'date': {
            if (typeof input !== 'string')
                return { violations: ['Doit être une chaîne de caractères.'] };
            if (!input.match(/^\d{4}-\d{2}-\d{2}$/))
                return { violations: ['Doit être au format ISO.'] };
            return undefined;
        }
    }
}
exports.default = default_1;
