"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeStopLoss = exports.makeTakeProfit = exports.makeTargetProfit = void 0;
function makeTargetProfit() {
    return {
        path: 'config.targetProfit',
        label: `Profit cible`,
        type: 'float',
        limit: [0, 100],
        default: 1,
        required: true,
        unit: '% gain',
    };
}
exports.makeTargetProfit = makeTargetProfit;
function makeTakeProfit() {
    return {
        path: 'config.takeProfit',
        label: `Take profit`,
        type: 'float',
        limit: [0, 100],
        default: 5,
        required: true,
        unit: '% gain',
    };
}
exports.makeTakeProfit = makeTakeProfit;
function makeStopLoss() {
    return {
        path: 'config.stopLoss',
        label: `Stop loss`,
        type: 'float',
        limit: [0, 100],
        default: 1,
        required: true,
        unit: '% perte',
    };
}
exports.makeStopLoss = makeStopLoss;
