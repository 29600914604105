"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(vars) {
    return function (target, make) {
        if (target.zone === 'none') {
            return;
        }
        if (!['price', 'volume', 'ratio', 'percent'].includes(target.unit)) {
            return;
        }
        const layer = target.zone === 'main' ? 'main' : target.unit;
        if (!vars[layer]) {
            vars[layer] = [];
        }
        vars[layer].push(make());
    };
}
exports.default = default_1;
