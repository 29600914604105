"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const range_1 = require("../range");
function default_1(candles, vars) {
    let values = [];
    for (const candle of candles) {
        values.push(candle.shape.volume);
    }
    for (const item of vars.filter((item) => item.unit === 'volume')) {
        values = values.concat(item.values);
    }
    const max = (0, range_1.makeTop)(Math.max(...values));
    const exp = max > 1e9 ? 6 : max > 1e6 ? 3 : 0;
    return {
        type: 'volume',
        min: 0,
        max,
        exp,
    };
}
exports.default = default_1;
