"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return {
        label: 'debug',
        title: 'Système de debug',
        info: 'Permet de tracer des données dans la console du navigateur.',
        code: [
            'main.console.trace(key: string, value: any): void;',
            '// Trace une valeur associée à une clé',
        ],
    };
}
exports.default = default_1;
