"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.download = exports.debug = void 0;
function debug(round) {
    if (!round.debug?.traces?.length) {
        return;
    }
    const table = [];
    for (const trace of round.debug.traces) {
        table.push({
            type: trace.type,
            path: trace.path.join('.'),
            key: trace.key,
            value: trace.value,
        });
    }
    console.groupCollapsed(`Round ${round.index}`);
    console.table(table);
    console.groupEnd();
}
exports.debug = debug;
function download(url, name) {
    const link = window.document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');
    link.setAttribute('download', name);
    window.document.body.appendChild(link);
    link.click();
    window.document.body.removeChild(link);
}
exports.download = download;
