"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(side, shape, last) {
    switch (side) {
        case 'low': {
            let value = shape.low;
            if (last && last.low === value) {
                value *= 1 + 1e7;
            }
            return value;
        }
        case 'high': {
            let value = shape.high;
            if (last && last.high === value) {
                value *= 1 - 1e7;
            }
            return value;
        }
    }
    return 0;
}
exports.default = default_1;
