"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return {
        label: 'target ?',
        title: 'Profit théorique / cible (optionnel)',
        code: [
            `env.target = undefined | {`,
            "    price: {",
            "        base: number,",
            "        // Prix moyen du deal opposé",
            "        limit: number,",
            "        // Prix pour réaliser profit nul",
            "        profit: number,",
            "        // Prix pour réaliser le profit cible",
            "        stopLoss: number,",
            "        // Prix de déclenchement du stop loss",
            "        takeProfit: number,",
            "        // Prix de déclenchement du take profit",
            "    },",
            "    live: {",
            "        profit: number,",
            "        // Profit théorique actuel en pourcent",
            "        distance: number,",
            "        // Distance entre les profits actuel et cible",
            "    },",
            '};',
        ],
    };
}
exports.default = default_1;
