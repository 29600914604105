"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styleShadow = exports.styleArrow = void 0;
function styleArrow(position, theme) {
    switch (position) {
        case 'top':
            return {
                left: `calc(50% - 5px)`,
                bottom: `-5px`,
                borderBottomWidth: '0',
                borderTopColor: theme.palette.background.paper,
            };
        case 'bottom':
            return {
                left: `calc(50% - 5px)`,
                top: `-5px`,
                borderTopWidth: '0',
                borderBottomColor: theme.palette.background.paper,
            };
        default:
            return {};
    }
}
exports.styleArrow = styleArrow;
function styleShadow(position, theme) {
    switch (position) {
        case 'top':
            return {
                left: `calc(50% - 5px)`,
                bottom: `-11px`,
                borderTopColor: 'rgba(0, 0, 0, 0.25)',
            };
        case 'bottom':
            return {
                left: `calc(50% - 5px)`,
                borderBottomColor: 'rgba(0, 0, 0, 0.1)',
            };
        default:
            return {};
    }
}
exports.styleShadow = styleShadow;
