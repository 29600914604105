"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return {
        label: 'just',
        title: 'Actions déclenchées au round précédent',
        code: [
            `env.just.open = number; // 0: faux, 1: vrai`,
            "// si vrai, le cycle vient de s'ouvrir",
            `env.just.buy = number; // 0: faux, 1: 1er achat, 2: 2nd achat, ...`,
            "// si vrai, une demande d'achat vient d'être déclenchée",
            `env.just.sell = number; // 0: faux, 1: 1ère vente, 2: 2nd vente, ...`,
            "// si vrai, une demande de vente vient d'être déclenchée",
            `env.just.bought = number; // 0: faux, 1: 1er achat, 2: 2nd achat, ...`,
            "// si vrai, un achat vient d'être réalisé",
            `env.just.sold = number; // 0: faux, 1: 1ère vente, 2: 2nd vente, ...`,
            "// si vrai, une vente vient d'être réalisée",
            `env.just.phase = number; // 0: faux, 1: vrai`,
            '// si vrai, le cycle vient de changer de phase',
            `env.just.reset = number; // 0: faux, 1: vrai`,
            '// si vrai, un nouveau cycle vient de démarrer',
        ],
    };
}
exports.default = default_1;
