"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(bindings, strategies) {
    const results = [];
    for (const binding of bindings) {
        const strategy = strategies[binding.child];
        if (!strategy) {
            continue;
        }
        results.push({ ...strategy, key: binding.key });
    }
    return results;
}
exports.default = default_1;
