"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const strategy_1 = require("./strategy");
const deals_1 = require("./deals");
const main_1 = require("./main");
const profit_1 = require("./profit");
const delay_1 = require("./delay");
const wallet_1 = require("./wallet");
function default_1(args) {
    const strategy = (0, strategy_1.makeStrategyConfig)(args);
    return [
        [(0, strategy_1.makeStrategyField)(args), (0, main_1.makeStartEnvelope)(args, strategy)],
        [(0, main_1.makeLoopPeriod)(strategy), (0, deals_1.makeMaxPurchases)(strategy)],
        [{ type: 'none' }, (0, deals_1.makeMaxSales)(strategy)],
        [(0, profit_1.makeStopLoss)(), (0, profit_1.makeTargetProfit)(), (0, profit_1.makeTakeProfit)(), (0, wallet_1.makeWalletReserve)()],
        [(0, delay_1.makeWarmupDelay)(), (0, delay_1.makeMarketDelay)(), (0, delay_1.makeRenewDelay)(), (0, wallet_1.makeWalletTreasure)()],
    ];
}
exports.default = default_1;
