"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.format = void 0;
const lodash_1 = require("lodash");
function format(props, data) {
    const result = {
        ...data,
        rank: props.type === 'update' ? props.data.rank : 999,
        config: (0, lodash_1.pick)(data.config, ['base', 'integer', 'graph']),
    };
    if (props.type !== 'update') {
        result.kind = props.data.kind;
        result.strategy = props.data.strategy;
    }
    return result;
}
exports.format = format;
