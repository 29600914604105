"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function default_1(item, column) {
    const value = column.value;
    if (typeof value === 'string') {
        return (0, lodash_1.get)(item, value);
    }
    if (typeof value === 'function') {
        return value(item);
    }
    return undefined;
}
exports.default = default_1;
