"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ns = ' ';
const nm = '–';
const float = new Intl.NumberFormat('fr-FR', { maximumSignificantDigits: 3 });
const count = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0 });
const currency = new Intl.NumberFormat('fr-FR', { maximumSignificantDigits: 5 });
const percent = new Intl.NumberFormat('fr-FR', { maximumSignificantDigits: 2 });
const normalize = (value) => value.replace(/ /g, ns).replace(/^-/, nm + ns);
function default_1(type, value) {
    if (typeof value === 'string') {
        return value;
    }
    switch (type) {
        case 'float':
            return normalize(float.format(value));
        case 'count':
            return normalize(count.format(value));
        case 'currency':
            return normalize(currency.format(value));
        case 'percent':
            return normalize(percent.format(value)) + ns + '%';
    }
}
exports.default = default_1;
