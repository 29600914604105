"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(logger, port) {
    return {
        read(id, listener) {
            return port.observe({ type: 'manager.server.read', id }, (feedback) => {
                if (feedback.type === 'manager.server.data') {
                    logger.debug('manager.server.data', { id, data: feedback.data });
                    listener(feedback.data);
                }
            });
        },
        list(listener) {
            return port.observe({ type: 'manager.server.list' }, (feedback) => {
                if (feedback.type === 'manager.server.listed') {
                    logger.debug('manager.server.list', { data: feedback.data });
                    listener(feedback.data);
                }
            });
        },
    };
}
exports.default = default_1;
