"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const utils_1 = require("./utils");
function default_1(state, env) {
    return {
        set(value) {
            state.data[0] = (0, utils_1.makeFloat)(value, env.base);
        },
        setPeriod(length) {
            state.period = (0, utils_1.makeNatural)(length) || 1;
        },
        reset() {
            state.data = [];
            state.limit = 1;
            state.period = 1;
        },
    };
}
exports.default = default_1;
