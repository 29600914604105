"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const styles_1 = require("@mui/styles");
const strokes = {
    'long-dash': '7,4',
    'short-dash': '5,3',
    'dash-dot': '7,3,2,3',
    dot: '2,2',
};
const styles = (theme) => ({});
function Component(props) {
    const [hover, setHover] = (0, react_1.useState)(false);
    const commands = [];
    for (const [index, value] of props.data.values.entries()) {
        commands.push(`${index > 0 ? 'L' : 'M'} ${props.time.getCenter(index)},${value}`);
    }
    const info = {
        type: 'curve',
        name: props.data.name,
        info: props.data.info,
        unit: props.data.unit,
        color: props.data.color,
        getValue(index) {
            return props.data.values[index] || 0;
        },
    };
    return (react_1.default.createElement("g", { onMouseEnter: () => {
            setHover(true);
            props.onInfo(info);
        }, onMouseLeave: () => {
            setHover(false);
            props.onInfo(null);
        } },
        react_1.default.createElement("path", { d: commands.join(' '), stroke: props.data.color || 'rgb(127, 127, 127)', strokeWidth: 10, opacity: hover ? 0.15 : 0, fill: "none", vectorEffect: "non-scaling-stroke" }),
        react_1.default.createElement("path", { d: commands.join(' '), stroke: props.data.color || 'rgb(127, 127, 127)', strokeWidth: 1, strokeDasharray: strokes[props.data.stroke || 'solid'], fill: "none", vectorEffect: "non-scaling-stroke" })));
}
Component.displayName = 'data.graph.show.base.vars.curve';
exports.default = (0, styles_1.withStyles)(styles)(Component);
