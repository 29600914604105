"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const shapeSpecs = [
    { key: 'open', name: 'Open', format: 'number.crypto' },
    { key: 'low', name: 'Low', format: 'number.crypto' },
    { key: 'high', name: 'High', format: 'number.crypto' },
    { key: 'close', name: 'Close', format: 'number.crypto' },
    { key: 'volume', name: 'Volume', format: 'number' },
    { key: 'trades', name: 'Trades', format: 'number.integer' },
];
function default_1(data, builder) {
    for (const spec of shapeSpecs) {
        builder.push({
            key: spec.key,
            type: 'dat',
            name: spec.name,
            format: spec.format,
            values: data.rounds.map((round) => round.candle.shape[spec.key]),
            access: `main.candle.${spec.key}.get()`,
        });
    }
}
exports.default = default_1;
