"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const canNames = {
    open: 'Open',
    buy: 'Buy',
    sell: 'Sell',
    phase: 'Phase',
    deal: 'Deal',
    cancel: 'Cancel',
};
const justNames = {
    open: 'Open',
    buy: 'Buy',
    sell: 'Sell',
    bought: 'Bought',
    sold: 'Sold',
    phase: 'Phase',
    reset: 'Reset',
};
function default_1(data, builder) {
    for (const key of Object.keys(canNames)) {
        builder.push({
            key: `can.${key}`,
            type: 'dat',
            name: `Can : ${canNames[key]}`,
            format: 'boolean',
            values: data.rounds.map((round) => !!round.env?.can[key]),
            access: `env.can.${key}`,
        });
    }
    for (const key of Object.keys(justNames)) {
        builder.push({
            key: `just.${key}`,
            type: 'dat',
            name: `Just : ${justNames[key]}`,
            format: 'boolean',
            values: data.rounds.map((round) => !!round.env?.just[key]),
            access: `env.just.${key}`,
        });
    }
}
exports.default = default_1;
