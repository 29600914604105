"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.expand = exports.flatten = void 0;
const separator = ':';
function flatten(key) {
    switch (key.app) {
        case 'master':
            return [key.app, key.role].join(separator);
        case 'manager':
            return [key.app, key.server].join(separator);
        case 'provider':
            return [key.app, key.market].join(separator);
        case 'analyzer':
            return [key.app, key.session].join(separator);
        case 'broker':
            return key.app;
        case 'jumper':
            return key.app;
    }
    throw new Error(`Tried to flatten invalid key: ${JSON.stringify(key)}.`);
}
exports.flatten = flatten;
function expand(key) {
    const bits = key.split(separator);
    switch (bits[0]) {
        case 'master':
            if (bits[1] === 'api')
                return { app: 'master', role: 'api' };
            if (bits[1] === 'bot')
                return { app: 'master', role: 'bot' };
            break;
        case 'manager':
            return { app: 'manager', server: bits[1] };
        case 'provider':
            return { app: 'provider', market: bits[1] };
        case 'analyzer':
            return { app: 'analyzer', session: bits[1] };
        case 'broker':
            return { app: 'broker' };
        case 'jumper':
            return { app: 'jumper' };
    }
    throw new Error(`Tried to expand invalid key: "${key}".`);
}
exports.expand = expand;
