"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(library) {
    const items = [];
    for (const key of Object.keys(library)) {
        items.push(compile(key, library[key]));
    }
    return items;
}
exports.default = default_1;
function compile(label, pkg) {
    const items = [];
    for (const key of Object.keys(pkg)) {
        const func = pkg[key];
        items.push({
            label: key,
            title: func.name,
            info: func.info,
            code: format(`${label}.${key}`, func),
        });
    }
    return { label, items };
}
function format(path, func) {
    const code = [`math.${path}(`];
    for (const arg of func.args) {
        const optional = arg.hasOwnProperty('default');
        code.push(`    ${arg.key}${optional ? '?' : ''}: ${arg.type},`);
        code.push(`    // ${arg.name}${optional ? ` (défaut = ${JSON.stringify(arg.default)})` : ''}`);
    }
    if (func.result.type === 'number' || func.result.type === 'number[]') {
        code.push(`): ${func.result.type};`);
    }
    if (func.result.type === 'object' || func.result.type === 'object[]') {
        code.push(func.result.type === 'object[]' ? '): Result[];' : '): Result;');
        code.push('');
        code.push('type Result = {');
        for (const prop of func.result.props) {
            code.push(`    ${prop.key}: ${prop.type};`);
            code.push(`    // ${prop.name}`);
        }
        code.push('};');
    }
    return code;
}
