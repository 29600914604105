"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return {
        label: 'blocking ?',
        title: 'Bloquage (optionnel)',
        code: [
            `env.blocking = undefined | {`,
            '    currency: string, // monnaie manquante',
            '    round: number, // round de début du bloquage',
            '    needed: number, // volume manquant',
            '};',
        ],
    };
}
exports.default = default_1;
