"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function default_1() {
    const state = { rounds: [], vars: {}, traces: [] };
    return {
        write(round, vars, debug) {
            const index = state.rounds.findIndex((item) => item.key.index === round.key.index);
            if (index >= 0) {
                state.rounds[index] = round;
                return;
            }
            state.rounds.push(round);
            state.vars = vars;
            (0, lodash_1.sortBy)(state.rounds, 'key.index', 'asc');
            if (state.rounds.length > 300) {
                state.rounds.splice(0, state.rounds.length - 300);
            }
            state.traces = state.traces.concat(debug.traces);
        },
        list(range) {
            return state.rounds.filter((round) => round.key.index >= range[0] && round.key.index <= range[1]);
        },
        read() {
            if (!state.rounds.length) {
                return undefined;
            }
            const round = state.rounds[state.rounds.length - 1];
            return {
                shape: round.shape,
                patterns: round.patterns,
                graph: round.graph,
                trend: round.trend,
                indicators: round.indicators,
            };
        },
        getVars() {
            return state.vars;
        },
        getDebug() {
            return { traces: state.traces.splice(0, state.traces.length) };
        },
    };
}
exports.default = default_1;
