"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterTags = exports.listTags = void 0;
const lodash_1 = require("lodash");
function listTags(items) {
    const counts = {};
    let empty = 0;
    for (const item of items) {
        const tags = (0, lodash_1.get)(item, 'tags', []);
        if (!tags.length) {
            empty++;
        }
        for (const tag of tags) {
            if (!counts[tag])
                counts[tag] = 0;
            counts[tag] += 1;
        }
    }
    const choices = [];
    if (empty > 0) {
        choices.push({ icon: 'status.empty', value: '', count: empty, info: 'Non balisé' });
    }
    for (const tag of (0, lodash_1.orderBy)(Object.keys(counts), (tag) => tag, 'asc')) {
        choices.push({ value: tag, label: tag, count: counts[tag] });
    }
    return choices;
}
exports.listTags = listTags;
function filterTags(items, selection) {
    const empty = selection.includes('');
    const choices = selection.filter((tag) => tag.length > 0);
    return selection.length
        ? items.filter((entity) => {
            const tags = (0, lodash_1.get)(entity, 'tags', []);
            return (!tags.length && empty) || (0, lodash_1.intersection)(tags, choices).length > 0;
        })
        : items;
}
exports.filterTags = filterTags;
