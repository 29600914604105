"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const field_1 = require("../field");
function default_1(form, memory, path) {
    const specs = memory.specs[path];
    const base = (0, lodash_1.get)(form.data, path, specs.default);
    const state = memory.states[path] || { input: undefined, status: 'same' };
    const input = state.input === undefined ? (0, field_1.format)(specs, base) : state.input;
    const error = (0, field_1.validate)(specs, input);
    if (specs.disabled) {
        state.status = 'disabled';
    }
    let problem = false;
    if (error) {
        problem = true;
        state.status = error.missing ? 'missing' : 'invalid';
        if (error.violations && error.violations.length) {
            state.violations = error.violations;
        }
    }
    if (state.failures && state.failures.length) {
        problem = true;
        state.status = 'invalid';
    }
    state.value = problem ? undefined : (0, field_1.normalize)(specs, input);
    if (!problem) {
        state.status = (0, field_1.equals)(specs, state.value, (base === null ? undefined : base)) ? 'same' : 'changed';
    }
    memory.states[path] = state;
}
exports.default = default_1;
