"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const utils_1 = require("./utils");
function Component(props) {
    let columns = [
        { label: 'Code', value: 'id', order: 'asc', search: true },
        { label: 'Rang', width: 'small', value: 'details.rank', format: 'number', order: 'asc', align: 'right' },
        { label: 'Nom', width: 'large', value: 'details.name', order: 'asc', search: true },
        { label: 'FCAS', width: 'small', value: 'details.fcas.score', format: 'number', order: 'asc' },
        { label: 'Prix (€)', value: 'price', format: 'number', order: 'desc', align: 'right' },
    ];
    if (props.query && props.query.used) {
        columns = columns.concat([
            { label: 'Envelope', value: 'config.envelope', format: 'number', order: 'desc', align: 'right' },
            { label: 'Min chg', value: 'config.minChange', format: 'number', order: 'desc', align: 'right' },
        ]);
    }
    columns = columns.concat([
        { label: 'Parent', width: 'small', value: 'details.parent.currency', filter: true },
        { label: 'Vol 24h', value: 'details.volumes.day', format: 'number.integer', order: 'desc', align: 'right' },
        {
            label: 'Cap (€)',
            value: 'details.capitalization',
            format: 'number.integer',
            order: 'desc',
            align: 'right',
        },
        { label: 'Volume', value: 'details.coins.total', format: 'number.integer', order: 'desc', align: 'right' },
        (0, utils_1.makeTags)(),
    ]);
    return props.render({
        columns,
        tags: true,
        type: 'table',
        order: { index: 0, direction: 'asc' },
    });
}
Component.displayName = 'crud.list.specs.currency';
exports.default = Component;
