"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const hooks_1 = require("./hooks");
function Component(props) {
    const state = (0, hooks_1.useAuthState)();
    if (state.status === 'authenticated' && state.user) {
        return props.render(state.user);
    }
    return props.login(state);
}
Component.displayName = 'store.auth.wrapper';
exports.default = Component;
