"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(data) {
    const changes = {};
    if (data.name) {
        changes.name = makeSuffix(data.name, ' ');
    }
    if (data.key) {
        changes.key = makeSuffix(data.key, '_');
    }
    return changes;
}
exports.default = default_1;
function makeSuffix(value, separator) {
    const parts = /^(.*?)(\d+)?$/.exec(value);
    if (!parts) {
        return `${value}${separator}2`;
    }
    const root = parts[1].replace(/\W$/, '');
    const version = parts[2] ? parseInt(parts[2]) : 1;
    return `${root}${separator}${version + 1}`;
}
