"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const priceNames = {
    base: 'Prix de base',
    limit: 'Prix limite',
    profit: 'Prix profit',
    stopLoss: 'Prix stop loss',
    takeProfit: 'Prix take profit',
};
const liveNames = {
    profit: 'Profit live',
    distance: 'DOPL',
};
function default_1(data, builder) {
    for (const key of Object.keys(priceNames)) {
        builder.push({
            key: `price.${key}`,
            type: 'dat',
            name: `${priceNames[key]}`,
            format: 'number.crypto',
            values: data.rounds.map((round) => round.env?.target?.price?.[key]),
            access: `env.target?.price.${key}`,
        });
    }
    for (const key of Object.keys(liveNames)) {
        builder.push({
            key: `live.${key}`,
            type: 'dat',
            name: `${priceNames[key]}`,
            format: 'number.percent',
            values: data.rounds.map((round) => round.env?.target?.live?.[key]),
            access: `env.target?.live.${key}`,
        });
    }
}
exports.default = default_1;
