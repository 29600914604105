"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(logger, port) {
    return {
        async deposit(account, currency, amount) {
            await port.execute({ type: 'wallet.deposit', account, currency, amount });
        },
        async sync(account) {
            await port.execute({ type: 'wallet.sync', account });
        },
        watchTotals(notify) {
            return port.observe({ type: 'wallet.totals' }, (feedback) => {
                if (feedback.type === 'wallet.totals') {
                    notify(feedback.totals);
                }
            });
        }
    };
}
exports.default = default_1;
