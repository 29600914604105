"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(rounds) {
    function makePeak(peak) {
        if (!peak) {
            return undefined;
        }
        return {
            weight: peak.weight,
            price: peak.price,
            line: makeLine(peak.line),
        };
    }
    function makeLine(line) {
        if (!line) {
            return undefined;
        }
        return {
            spread: line.spread,
            slope: line.slope,
            length: line.length,
            peaks: line.peaks,
        };
    }
    return rounds.map((round) => ({
        shape: {
            open: round.candle.shape.open,
            low: round.candle.shape.low,
            high: round.candle.shape.high,
            close: round.candle.shape.close,
            volume: round.candle.shape.volume,
            trades: round.candle.shape.trades,
        },
        graph: {
            low: makePeak(round.candle.graph ? round.candle.graph.low : undefined),
            high: makePeak(round.candle.graph ? round.candle.graph.high : undefined),
        },
    }));
}
exports.default = default_1;
