"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeTrigger = exports.makeFloat = exports.makeNatural = void 0;
function makeNatural(value) {
    return Math.max(0, Math.round(makeFloat(value, 0)));
}
exports.makeNatural = makeNatural;
function makeFloat(value, base) {
    if (typeof value !== 'number' || isNaN(value)) {
        return base;
    }
    return value;
}
exports.makeFloat = makeFloat;
function makeTrigger(period, round) {
    if (period <= 1) {
        return true;
    }
    const modulo = round % period;
    return modulo >= 0.5 && modulo < 1.5;
}
exports.makeTrigger = makeTrigger;
