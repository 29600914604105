"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return {
        label: 'market',
        items: [
            {
                label: 'fees',
                title: 'Ratios de commission',
                code: [
                    '// ces nombres sont des ratios, pas des %',
                    `env.market.fees.taker = number;`,
                    '// commission pour les ordres sur le marché',
                    `env.market.fees.maker = number;`,
                    '// commission pour les ordres limités',
                ],
            },
            {
                label: 'limits',
                title: "Limites d'enveloppes",
                code: [
                    `env.market.limits.purchase = number;`,
                    "// Enveloppe minimum d'achat",
                    `env.market.limits.sale = number;`,
                    '// Enveloppe minimum de vente',
                ],
            },
            {
                label: 'walls',
                title: "Murs de l'order book",
                code: [
                    '// Si aucun mur, le prix est celui de la',
                    '// dernière position prise en compte (20ème)',
                    `env.market.walls.ask = number;`,
                    '// Prix du premier mur des asks',
                    `env.market.walls.bid = number;`,
                    '// Prix du premier mur des bids',
                ],
            },
            {
                label: 'candle(xx)',
                title: 'Données des chandelles',
                code: [
                    'env.market.candle[xx].index: number;',
                    '// index de la dernière chandelle',
                    'env.market.candle[xx].updated: 0 | 1;',
                    '// vaut 1 si la chandelle a été mise à jour depuis le round précédent',
                    'env.market.candle[xx].[min/max].price: number;',
                    '// prix du dernier pic min/max',
                    'env.market.candle[xx].[min/max].weight: number;',
                    '// poids du dernier pic min/max',
                    'env.market.candle[xx].[min/max].age: number;',
                    '// âge du dernier pic min/max',
                ],
            },
        ],
    };
}
exports.default = default_1;
