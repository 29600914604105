"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(scope) {
    function format(message) {
        return `[${scope}] ${message.key}`;
    }
    return function (message) {
        switch (message.type) {
            case 'alert':
                return console.error(format(message), message.data);
            case 'warning':
                return console.warn(format(message), message.data);
            case 'notice':
                return console.log(format(message), message.data);
            case 'debug':
                return console.debug(format(message), message.data);
        }
    };
}
exports.default = default_1;
