"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const views_store_1 = require("@brc/views-store");
const views_crud_1 = require("@brc/views-crud");
const views_base_1 = require("@brc/views-base");
function Component(props) {
    const [create, setCreate] = (0, react_1.useState)();
    const [state, setState] = (0, views_store_1.useLocalState)([...props.path, 'state'], {
        filter: {},
        order: { index: 0, direction: 'asc' },
    });
    return (react_1.default.createElement(react_1.default.Fragment, null,
        create ? (react_1.default.createElement(views_crud_1.Form, { trigger: [!!create, () => setCreate(undefined)], type: "create", model: "session", data: {
                type: props.type,
                account: create.account.id,
                market: create.market.id,
                config: { startCurrency: create.market.quote },
            }, onSaved: props.onOpen })) : null,
        react_1.default.createElement(views_base_1.Table, { items: props.items, empty: { text: 'Aucun marché disponible' }, onClick: (item) => setCreate(item), columns: props.columns, state: state, onState: setState })));
}
Component.displayName = 'trade.session.markets.table';
exports.default = Component;
