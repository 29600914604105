"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const styles_1 = require("@mui/styles");
const styles = (theme) => ({
    up: {
        fill: theme.palette.success.main,
    },
    down: {
        fill: theme.palette.error.main,
    },
    line: {
        stroke: theme.palette.text.primary,
        strokeDasharray: '7,4',
        opacity: 0.5,
    },
});
function Component(props) {
    const [hover, setHover] = (0, react_1.useState)(false);
    const items = [];
    const top = Math.max(props.data.open, props.data.close);
    const bottom = Math.min(props.data.open, props.data.close);
    const high = Math.max(top, props.data.high);
    const low = Math.min(bottom, props.data.low);
    const center = props.time.getCenter(props.index);
    const className = props.data.open < props.data.close ? props.classes.up : props.classes.down;
    if (props.data.high > top) {
        items.push(react_1.default.createElement("rect", { key: "high", x: center - 0.5, y: top, width: 1, height: props.data.high - top, className: className, opacity: 0.75 }));
    }
    if (top !== bottom) {
        items.push(react_1.default.createElement("rect", { key: "body", x: props.time.getStart(props.index), width: props.time.width, y: bottom, height: top - bottom, strokeWidth: 0, className: className, opacity: 0.75 }));
    }
    if (props.data.low < bottom) {
        items.push(react_1.default.createElement("rect", { key: "low", x: center - 0.5, y: props.data.low, width: 1, height: bottom - props.data.low, className: className, opacity: 0.75 }));
    }
    if (!items.length) {
        return null;
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        hover ? (react_1.default.createElement("g", null,
            react_1.default.createElement("rect", { x: center - 5000, width: 10000, y: low, height: high - low, opacity: 0.05, strokeWidth: 0, className: className }),
            react_1.default.createElement("line", { x1: center - 5000, y1: high, x2: center + 5000, y2: high, vectorEffect: "non-scaling-stroke", className: props.classes.line }),
            react_1.default.createElement("line", { x1: center - 5000, y1: low, x2: center + 5000, y2: low, vectorEffect: "non-scaling-stroke", className: props.classes.line }))) : null,
        react_1.default.createElement("g", { onMouseEnter: () => setHover(true), onMouseLeave: () => setHover(false), opacity: hover ? 1 : 0.85 }, items)));
}
Component.displayName = 'data.graph.show.base.candles.shape';
exports.default = (0, styles_1.withStyles)(styles)(Component);
