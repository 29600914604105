"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(input, register) {
    for (const decider of input.model.deciders) {
        for (const item of decider.vars) {
            register(item, () => ({
                key: `deciders.${decider.key}.${item.key}`,
                name: item.name,
                info: item.info,
                stroke: item.stroke,
                color: item.color,
                unit: item.unit,
                values: input.rounds.map((round) => round.vars?.[`deciders.${decider.key}.${item.key}`] || 0),
            }));
        }
    }
}
exports.default = default_1;
