"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const utils_1 = require("./utils");
function default_1(logger, port) {
    const owned = {};
    const asked = {};
    async function maintain(key) {
        await port.execute({ type: 'lock.maintain', key });
    }
    async function acquire(key) {
        const hash = (0, utils_1.makeHash)(key);
        const response = await port.execute({ type: 'lock.acquire', key });
        if (response.type === 'lock.status' && response.status.type === 'owned') {
            owned[hash] = setInterval(() => maintain(key), 45e3);
            delete asked[hash];
        }
        else {
            asked[hash] = true;
        }
    }
    return {
        async start(key) {
            const hash = (0, utils_1.makeHash)(key);
            if (asked.hasOwnProperty(hash)) {
                delete asked[hash];
            }
            if (owned.hasOwnProperty(hash)) {
                return;
            }
            await acquire(key);
        },
        async stop(key) {
            const hash = (0, utils_1.makeHash)(key);
            if (owned.hasOwnProperty(hash)) {
                await port.execute({ type: 'lock.release', key });
                clearInterval(owned[hash]);
                delete owned[hash];
            }
            if (asked.hasOwnProperty(hash)) {
                delete asked[hash];
            }
        },
        async notify(key, status) {
            const hash = (0, utils_1.makeHash)(key);
            if (asked.hasOwnProperty(hash) && status.type === 'free') {
                await acquire(key);
            }
        },
    };
}
exports.default = default_1;
