"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function default_1(listen, watchers) {
    const disposers = [];
    let completions = [];
    for (const watcher of watchers) {
        const dispose = watcher((items) => {
            completions = (0, lodash_1.uniqBy)(completions.concat(items), 'value');
            listen(completions);
        });
        if (dispose) {
            disposers.push(dispose);
        }
    }
    return () => {
        for (const disposer of disposers) {
            disposer();
        }
    };
}
exports.default = default_1;
