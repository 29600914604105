"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.move = exports.unwrap = exports.wrap = void 0;
function wrap(items) {
    return items.map((data, index) => ({ index, data }));
}
exports.wrap = wrap;
function unwrap(items) {
    return items.map((item) => item.data);
}
exports.unwrap = unwrap;
function move(items, from, to) {
    const result = items.slice();
    const insert = result.splice(from, 1);
    result.splice(to, 0, ...insert);
    return result.map((item, index) => ({ index, data: item.data }));
}
exports.move = move;
