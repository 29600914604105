"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const uuid_1 = require("uuid");
function default_1() {
    const listeners = {};
    return {
        listen(listener) {
            const id = (0, uuid_1.v4)();
            listeners[id] = listener;
            return () => delete listeners[id];
        },
        send(event) {
            for (const id of Object.keys(listeners)) {
                try {
                    listeners[id](event);
                }
                catch (error) {
                }
            }
        },
        count() {
            return Object.keys(listeners).length;
        },
    };
}
exports.default = default_1;
