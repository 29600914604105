"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(logger, type, data, path) {
    return new Proxy(data || {}, {
        get(_, key) {
            if (typeof key !== 'string') {
                return (data || {})[key];
            }
            if (data.hasOwnProperty(key)) {
                if (key === 'set') {
                    return wrapSet(logger, data[key], [...path, key]);
                }
                return data[key];
            }
            logger.warn('core.script.unknown', { data, path, key });
            return () => 0;
        },
    });
}
exports.default = default_1;
function wrapSet(logger, call, path) {
    return new Proxy(call, {
        apply(_, self, args) {
            const value = args ? args[0] : undefined;
            if (typeof value !== 'number' || isNaN(value) || !isFinite(value)) {
                logger.warn('core.script.invalid', { path, value });
                return;
            }
            return call(...args);
        },
    });
}
