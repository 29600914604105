"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(sync, data) {
    const setup = {
        cellWidth: 150,
        cellHeight: 40,
        asideWidth: 75,
        headHeight: 60,
        overScanX: 2,
        overScanY: 5,
    };
    return {
        corner: makeCornerSize(setup, sync, data),
        header: makeHeaderSize(setup, sync, data),
        aside: makeAsideSize(setup, sync, data),
        values: makeValuesSize(setup, sync, data),
    };
}
exports.default = default_1;
function makeCornerSize(setup, sync, data) {
    return {
        cellWidth: setup.asideWidth,
        cellHeight: setup.headHeight,
        width: data.aside.labels.length * setup.asideWidth,
        height: setup.headHeight,
        columns: data.aside.labels.length,
        rows: 1,
        overScanX: 0,
        overScanY: 0,
        scrollLeft: 0,
        scrollTop: 0,
        onScroll: undefined,
    };
}
function makeHeaderSize(setup, sync, data) {
    return {
        cellWidth: setup.cellWidth,
        cellHeight: setup.headHeight,
        width: sync.clientWidth - data.aside.labels.length * setup.asideWidth,
        height: setup.headHeight,
        columns: data.main.labels.length,
        rows: 1,
        overScanX: setup.overScanX,
        overScanY: 0,
        scrollLeft: sync.scrollLeft,
        scrollTop: 0,
        onScroll: undefined,
    };
}
function makeAsideSize(setup, sync, data) {
    return {
        cellWidth: setup.asideWidth,
        cellHeight: setup.cellHeight,
        width: data.aside.labels.length * setup.asideWidth,
        height: sync.clientHeight - setup.headHeight,
        columns: data.aside.labels.length,
        rows: data.aside.values.length ? data.aside.values[0].length : 0,
        overScanX: 0,
        overScanY: setup.overScanY,
        scrollLeft: 0,
        scrollTop: sync.scrollTop,
        onScroll: undefined,
    };
}
function makeValuesSize(setup, sync, data) {
    return {
        cellWidth: setup.cellWidth,
        cellHeight: setup.cellHeight,
        width: sync.clientWidth - data.aside.labels.length * setup.asideWidth,
        height: sync.clientHeight - setup.headHeight,
        columns: data.main.labels.length,
        rows: data.main.values.length ? data.main.values[0].length : 0,
        overScanX: setup.overScanX,
        overScanY: setup.overScanY,
        scrollLeft: sync.scrollLeft,
        scrollTop: sync.scrollTop,
        onScroll: sync.onScroll,
    };
}
