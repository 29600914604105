"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(model, id) {
    return function (complete) {
        return model.list('binding', { parent: id }, (bindings) => {
            const completions = [];
            for (const binding of bindings) {
                if (binding.types.child === 'decider') {
                    completions.push({ value: `deps.${binding.key}.setup()`, meta: 'function' });
                    completions.push({ value: `deps.${binding.key}.decide()`, meta: 'function' });
                    completions.push({ value: `deps.${binding.key}.reset()`, meta: 'function' });
                }
                if (binding.types.child === 'computer') {
                    completions.push({ value: `deps.${binding.key}.setup()`, meta: 'function' });
                    completions.push({ value: `deps.${binding.key}.compute()`, meta: 'function' });
                    completions.push({ value: `deps.${binding.key}.reset()`, meta: 'function' });
                }
            }
            complete(completions);
        });
    };
}
exports.default = default_1;
