"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(stats) {
    const choices = [];
    for (const tag of Object.keys(stats.tags || {})) {
        choices.push({ value: tag, label: tag });
    }
    return { path: 'tags', label: 'Balises', type: 'tags', choices, default: [] };
}
exports.default = default_1;
