"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function default_1(form, memory) {
    const data = {};
    let errors = 0;
    for (const path of Object.keys(memory.specs)) {
        const state = memory.states[path];
        const specs = memory.specs[path];
        if (!state || specs.disabled) {
            continue;
        }
        let value = state.value;
        if (value === undefined && state.status === 'same')
            value = (0, lodash_1.get)(form.data, path);
        if (value === undefined)
            value = specs.default;
        if (value === undefined)
            value = null;
        if (['invalid', 'missing'].includes(state.status)) {
            errors += 1;
        }
        if (['same', 'changed'].includes(state.status)) {
            (0, lodash_1.set)(data, path, value);
        }
    }
    return { errors, data };
}
exports.default = default_1;
