"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const hooks_1 = require("./hooks");
function Component(props) {
    const totals = (0, hooks_1.useWalletTotals)();
    if (!totals) {
        return props.loader || null;
    }
    return props.render(props.nulls ? totals : totals.filter((total) => total.total > 0));
}
Component.displayName = 'store.wallet.totals';
exports.default = Component;
