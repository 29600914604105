"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.forgePriceFromVolumes = exports.convertBaseToQuote = exports.convertQuoteToBase = exports.encodeSymbol = exports.decodeSymbol = void 0;
const float_1 = require("./float");
function decodeSymbol(symbol) {
    const [base, quote] = symbol.split('/');
    if (!base || !quote) {
        throw new Error(`Cannot extract currencies from invalid "${symbol}" symbol.`);
    }
    return { base, quote };
}
exports.decodeSymbol = decodeSymbol;
function encodeSymbol(currencies) {
    return [currencies.base, currencies.quote].join('/');
}
exports.encodeSymbol = encodeSymbol;
function convertQuoteToBase(args) {
    return args.price ? (0, float_1.fixFloat)(args.quote / args.price) : 0;
}
exports.convertQuoteToBase = convertQuoteToBase;
function convertBaseToQuote(args) {
    return (0, float_1.fixFloat)(args.base * args.price);
}
exports.convertBaseToQuote = convertBaseToQuote;
function forgePriceFromVolumes(args) {
    return args.quote ? (0, float_1.fixFloat)(args.base / args.quote) : 0;
}
exports.forgePriceFromVolumes = forgePriceFromVolumes;
