"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const material_1 = require("@mui/material");
const styles_1 = require("@mui/styles");
const styles = (theme) => ({
    container: {
        margin: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        width: '100px',
        fontWeight: 500,
    },
    max: {
        flexGrow: 1,
        marginLeft: theme.spacing(1),
    },
    input: {
        width: '150px',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontWeight: 500,
        padding: theme.spacing(1),
        border: `2px solid ${theme.palette.border.main}`,
        borderRadius: theme.shape.borderRadius,
        '&:not(:disabled):hover': {
            borderColor: theme.palette.border.hard,
        },
        '&:focus': {
            outline: 'none',
            borderColor: theme.palette.primary.main,
        },
        '&:disabled': {
            outline: 'none',
            borderColor: theme.palette.border.main,
            background: 'transparent',
            color: theme.palette.text.secondary,
        },
    },
    invalid: {
        borderColor: `${theme.palette.secondary.main} !important`,
    },
});
function Component(props) {
    const [value, setValue] = (0, react_1.useState)(String(props.data));
    const [valid, setValid] = (0, react_1.useState)(true);
    const classes = [props.classes.input];
    if (!valid)
        classes.push(props.classes.invalid);
    return (react_1.default.createElement("div", { className: props.classes.container },
        react_1.default.createElement(material_1.Typography, { className: props.classes.title, variant: "body1", color: props.asset ? 'primary' : 'textSecondary' }, props.label),
        react_1.default.createElement("input", { disabled: !props.asset, className: classes.join(' '), type: "number", value: value, onChange: (event) => {
                const value = event.target.value;
                setValue(value);
                const match = !!value.match(/\d+\.?\d*/);
                const data = match ? parseFloat(value) : 0;
                const okay = match && data <= props.asset && data >= 0;
                setValid(okay);
                props.onData(okay ? data : 0);
            } }),
        react_1.default.createElement(material_1.Typography, { className: props.classes.max, variant: "body2", color: props.asset ? 'textPrimary' : 'textSecondary' },
            "Max: ",
            props.asset)));
}
Component.displayName = 'macro.thread.trigger.injection.value';
exports.default = (0, styles_1.withStyles)(styles)(Component);
