"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.build = exports.parse = void 0;
function parse(url) {
    const [pathname, search] = url.split('?');
    const path = (pathname || '').split('/').filter((name) => !!name);
    const query = {};
    for (const bit of (search || '').split('&')) {
        const [key, value] = bit.split('=');
        if (key && value)
            query[key] = value;
    }
    return {
        root: path[0] || '',
        path: path.slice(1),
        query,
    };
}
exports.parse = parse;
function build(url) {
    const path = [url.root, ...url.path.filter((v) => !!v)];
    const query = Object.keys(url.query || {})
        .map((key) => `${key}=${url.query[key]}`)
        .join('&');
    return '/' + path.join('/') + (query ? `?${query}` : '');
}
exports.build = build;
