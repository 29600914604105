"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(logger, port) {
    async function execute(type, key) {
        const feedback = await port.execute({ type, key });
        if (feedback.type !== 'manager.process.result') {
            logger.alert('manager.process.invalid', { command: type, key, result: feedback.type });
            return false;
        }
        if (feedback.error) {
            logger.alert('manager.process.error', { command: type, key, error: feedback.error });
            return false;
        }
        logger.debug('manager.process.result', { key, data: feedback.data });
        return true;
    }
    return {
        start(key) {
            return execute('manager.process.start', key);
        },
        setup(key) {
            return execute('manager.process.setup', key);
        },
        stop(key) {
            return execute('manager.process.stop', key);
        },
        read(key, listener) {
            return port.observe({ type: 'manager.process.read', key }, (feedback) => {
                if (feedback.type === 'manager.process.data') {
                    logger.debug('manager.process.data', { key, data: feedback.data });
                    listener(feedback.data);
                }
            });
        },
        list(listener) {
            return port.observe({ type: 'manager.process.list' }, (feedback) => {
                if (feedback.type === 'manager.process.listed') {
                    logger.debug('manager.process.list', { data: feedback.data });
                    listener(feedback.data);
                }
            });
        },
    };
}
exports.default = default_1;
