"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function default_1(args) {
    const children = [];
    const config = [];
    let defaultKey;
    for (const child of args.children) {
        children.push({ value: child.id, label: child.name });
        if (args.data.child === child.id) {
            defaultKey = child.key;
        }
    }
    if (args.data.child) {
        const parameters = args.parameters.filter((parameter) => parameter.strategy === args.data.child);
        for (const batch of (0, lodash_1.chunk)(parameters, 3)) {
            const group = [];
            for (const parameter of batch) {
                group.push({
                    type: 'float',
                    path: `config.parameters.${parameter.id}`,
                    label: parameter.name,
                    default: parameter.config?.base || 0,
                });
            }
            for (let i = batch.length; i < 3; i++) {
                group.push({ type: 'none' });
            }
            config.push(group);
        }
        if (!config.length) {
            config.push([{ type: 'none', value: "La stratégie sélectionnée n'a aucun paramètre" }]);
        }
    }
    else {
        config.push([{ type: 'none', value: 'Pas de stratégie sélectionnée' }]);
    }
    return [
        [
            { path: 'child', label: 'Stratégie', width: 2, type: 'select', required: true, choices: children },
            { path: 'key', label: 'Clé', width: 1, type: 'key', limit: [2, 32], default: defaultKey },
        ],
        ...config,
    ];
}
exports.default = default_1;
