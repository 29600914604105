"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return function (complete) {
        complete([
            { value: 'main.buy()', meta: 'function' },
            { value: 'main.sell()', meta: 'function' },
            { value: 'main.cancel()', meta: 'function' },
            { value: 'main.getBids()', meta: 'function' },
            { value: 'main.getAsks()', meta: 'function' },
            { value: 'main.getUsed()', meta: 'function' },
            { value: 'main.chain()', meta: 'function' },
            { value: 'main.wait()', meta: 'function' },
        ]);
    };
}
exports.default = default_1;
