"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function default_1(computers) {
    const items = [];
    for (const computer of (0, lodash_1.orderBy)(computers, 'key', 'asc')) {
        items.push({
            label: computer.key,
            title: computer.name,
            info: computer.info,
            code: [
                `// Modification de la configuration du module`,
                `deps.${computer.key}.setup(conf: { [key: string]: number }): void;`,
                `// Lancement des calculs et retour du résultat`,
                `deps.${computer.key}.compute(args: Object): { [key: string]: number };`,
                `// Remise à zéro des variables`,
                `deps.${computer.key}.reset(): void;`,
                `// Lecture de la configuration actuelle`,
                `deps.${computer.key}.conf: { [key: string]: number };`,
                `// Lecture des variables actuelle`,
                `deps.${computer.key}.vars: { [key: string]: Object };`,
            ],
        });
    }
    return items;
}
exports.default = default_1;
