"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function default_1(input, register) {
    for (const indicator of input.model.indicators) {
        register(indicator, () => ({
            key: `candle.${indicator.key}`,
            name: indicator.name,
            info: indicator.info,
            stroke: indicator.stroke,
            color: indicator.color,
            unit: indicator.unit,
            values: input.rounds.map((round) => (0, lodash_1.get)(round, `candle.indicators.${indicator.key}`, 0)),
        }));
    }
}
exports.default = default_1;
