"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(error, data) {
    const context = data || {};
    // pm2 throws array of errors with a single item
    if (Array.isArray(error) && error.length === 1) {
        error = error[0];
    }
    if (error instanceof Error) {
        context.error = {
            constructor: error.constructor ? error.constructor.name : undefined,
            message: error.message,
            stack: error.stack,
        };
    }
    else {
        context.error = error;
    }
    return context;
}
exports.default = default_1;
