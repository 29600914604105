"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(theme) {
    return {
        MuiCssBaseline: {
            styleOverrides: {
                '::-webkit-scrollbar': { width: theme.spacing(1), height: theme.spacing(3) },
                '::-webkit-scrollbar-track': { background: 'transparent' },
                '::-webkit-resizer': { background: 'transparent' },
                '::-webkit-scrollbar-corner': { background: 'transparent' },
                '::-webkit-scrollbar-button': { display: 'none' },
                '::-webkit-scrollbar-thumb': { background: theme.palette.background.scrollbar },
            },
        },
        MuiList: {
            styleOverrides: {
                root: { outline: 'none' },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    alignItems: 'center',
                    '& > svg': { marginRight: theme.spacing(2), opacity: 0.33 },
                    '&.Mui-selected, &.Mui-selected:hover': {
                        backgroundColor: theme.palette.primary.main + ' !important',
                        color: theme.palette.primary.contrastText + ' !important',
                        '& > svg': { opacity: 0.67 },
                    },
                    '&:hover': {
                        backgroundColor: theme.palette.primary.background,
                    },
                },
            },
        },
    };
}
exports.default = default_1;
