"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function default_1(args) {
    return {
        configure: args.configure,
        validate: args.validate || ((data) => Promise.resolve([])),
        normalize(data) {
            return args.normalize ? args.normalize((0, lodash_1.cloneDeep)(data)) : (0, lodash_1.cloneDeep)(data);
        },
        async handle(data) {
            if (args.handle) {
                return args.handle(data);
            }
            const changes = args.format ? args.format(data) : data;
            const initial = (0, lodash_1.pick)(args.props.data, Object.keys(changes));
            const values = args.merge ? args.merge(initial, changes) : { ...initial, ...changes };
            const model = args.props.model;
            const owner = args.owner ? args.owner.id : undefined;
            switch (args.props.type) {
                case 'create': {
                    const create = { ...values, model, owner };
                    return (await args.model.create(create));
                }
                case 'update': {
                    const update = { ...values, model, id: args.props.data.id };
                    return (await args.model.update(update));
                }
                case 'clone': {
                    const create = { ...values, model, owner };
                    return args.clone
                        ? args.clone(args.props.data, create)
                        : (await args.model.create(create));
                }
            }
        },
    };
}
exports.default = default_1;
