"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const const_1 = require("./const");
function default_1(data, bounds) {
    const width = {
        total: bounds.width,
        left: const_1.sideWidth,
        main: bounds.width - const_1.sideWidth * 2,
        right: const_1.sideWidth,
    };
    const height = {
        total: bounds.height,
        zones: data.zones.enabled ? data.zones.units.length * (const_1.zoneHeight + const_1.layerBorder) : 0,
        thread: data.thread.enabled ? const_1.roundHeight + const_1.layerBorder : 0,
        patterns: data.patterns.enabled ? const_1.roundHeight + const_1.layerBorder : 0,
        main: 0,
        time: const_1.timeHeight + const_1.layerBorder,
        extra: 0,
    };
    const rest = Math.max(0, height.total - height.zones - height.thread - height.patterns - height.time);
    height.extra = Math.min(const_1.extraHeight, rest / 2 / data.extra.length);
    height.main = Math.max(0, rest - height.extra * data.extra.length);
    return { width, height };
}
exports.default = default_1;
