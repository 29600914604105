"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(rounds) {
    if (!rounds.length) {
        return { min: makeEmpty(), max: makeEmpty() };
    }
    let min;
    let max;
    for (const [index, round] of rounds.entries()) {
        if (!round.graph) {
            continue;
        }
        const low = round.graph.low;
        const high = round.graph.high;
        if (!min && low) {
            min = { price: low.price, weight: low.weight, age: index + 1 };
        }
        if (!max && high) {
            max = { price: high.price, weight: high.weight, age: index + 1 };
        }
        if (min && max) {
            return { min, max };
        }
    }
    return { min: min || makeEmpty(), max: max || makeEmpty() };
}
exports.default = default_1;
function makeEmpty() {
    return { price: 0, weight: 0, age: 0 };
}
