"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.labels = exports.choices = void 0;
const choices = [
    { label: 'Non affiché', value: 'none' },
    { label: 'Principal', value: 'main' },
    { label: 'Secondaire', value: 'extra' },
];
exports.choices = choices;
const labels = {
    none: '',
    main: 'Principal',
    extra: 'Secondaire',
};
exports.labels = labels;
