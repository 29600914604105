"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const core_utils_1 = require("@brc/core-utils");
function default_1(threads) {
    const actions = [];
    const sigma = { volume: 0, snowball: 0, reserve: 0, treasure: 0 };
    for (const thread of (0, lodash_1.orderBy)(threads, 'details.start.stamp.time', 'asc')) {
        let index = 0;
        for (const event of thread.events) {
            if (event.type === 'deal') {
                index++;
                for (const order of event.deal.orders) {
                    const { base, quote } = (0, core_utils_1.decodeSymbol)(event.deal.symbol);
                    const cc = event.deal.type === 'purchase' ? quote : base;
                    const fc = event.deal.type === 'purchase' ? base : quote;
                    actions.push({
                        start: order.start,
                        index: { thread: thread.start.index, phase: 0, deal: 0 },
                        label: event.deal.type === 'purchase' ? 'Achat' : 'Vente',
                        status: order.status,
                        duration: order.duration,
                        price: order.status === 'closed' ? order.price : undefined,
                        cost: order.status === 'closed' ? { volume: order.cost, currency: cc } : undefined,
                        filled: order.status === 'closed' ? { volume: order.filled, currency: fc } : undefined,
                        fee: order.status === 'closed' ? order.fee || { volume: 0, currency: '?' } : undefined,
                    });
                }
            }
            if (event.type === 'closing' &&
                thread.result &&
                thread.start.index === actions[actions.length - 1]?.index?.thread) {
                const profit = thread.result.profit;
                sigma.volume += profit.volume;
                sigma.snowball += profit.snowball;
                sigma.reserve += profit.reserve;
                sigma.treasure += profit.treasure;
                actions[actions.length - 1].profit = profit;
                actions[actions.length - 1].sigma = { ...sigma };
            }
        }
    }
    return actions;
}
exports.default = default_1;
