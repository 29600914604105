"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const hooks_1 = require("./hooks");
function Component(props) {
    const status = (0, hooks_1.useLockStatus)({ model: props.model, id: props.id, scope: props.scope });
    if (!status) {
        return props.loader || null;
    }
    return props.render(status);
}
Component.displayName = 'store.lock.watch';
exports.default = Component;
