"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(logger, port) {
    return {
        async validate(query) {
            const command = { type: 'validator.query', query };
            const feedback = await port.execute(command);
            if (feedback.type !== 'validator.result') {
                logger.alert('model.feedback.invalid', { command, feedback });
                throw new Error(`Invalid feedback for validator result.`);
            }
            return feedback.errors;
        },
    };
}
exports.default = default_1;
