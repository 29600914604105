"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return {
        name: 'Maximum',
        info: "Retourne le plus grand nombre d'une liste donée",
        args: [{ key: 'value', name: 'Liste de nombres', type: 'number[]' }],
        result: { type: 'number' },
        call(values) {
            return Math.max(...values) || 0;
        },
    };
}
exports.default = default_1;
