"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    const low = { index: 0, price: 0, weight: 0 };
    const high = { index: 0, price: 0, weight: 0 };
    return {
        update(index, graph) {
            update(low, index, graph.low);
            update(high, index, graph.high);
        },
        read(index) {
            return { min: fetch(low, index), max: fetch(high, index) };
        },
    };
}
exports.default = default_1;
function update(state, index, value) {
    if (!value || index <= state.index) {
        return;
    }
    state.index = index;
    state.price = value.price;
    state.weight = value.weight;
}
function fetch(state, index) {
    if (!state.weight) {
        return { price: 0, weight: 0, age: 0 };
    }
    return {
        price: state.price,
        weight: state.weight,
        age: index - state.index,
    };
}
