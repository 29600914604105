"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.format = exports.normalize = void 0;
function normalize(data) {
    return data;
}
exports.normalize = normalize;
function format(props, data) {
    const config = {
        parameters: {},
        ...(props.data.config || {}),
        ...data.config,
        renewDelay: 0,
        marketDelay: 2000,
        maxPurchases: data.config.maxPurchases || 1,
        maxSales: data.config.maxSales || 1,
        autoInject: 'none',
    };
    const result = { ...data, config };
    if (props.type !== 'update') {
        result.type = 'analyzer';
        result.market = props.data.market;
        result.account = props.data.account;
    }
    return result;
}
exports.format = format;
