"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeMaxSales = exports.makeMaxPurchases = void 0;
function makeMaxPurchases(config) {
    if (!config) {
        return { type: 'none', value: 'Aucune stratégie sélectionnée' };
    }
    if (!config.manyPurchases) {
        return { type: 'none', value: 'Achats multiples non supportés' };
    }
    if (config.minPurchases === config.maxPurchases) {
        return { type: 'none', value: `Fixé à ${config.minPurchases} achats` };
    }
    return {
        path: 'config.maxPurchases',
        label: "Nombre d'achats",
        type: 'range',
        limit: [config.minPurchases, config.maxPurchases],
        step: 1,
        default: config.minPurchases,
        required: true,
        unit: 'achats',
    };
}
exports.makeMaxPurchases = makeMaxPurchases;
function makeMaxSales(config) {
    if (!config) {
        return { type: 'none', value: 'Aucune stratégie sélectionnée' };
    }
    if (!config.manySales) {
        return { type: 'none', value: 'Ventes multiples non supportés' };
    }
    if (config.minSales === config.maxSales) {
        return { type: 'none', value: `Fixé à ${config.minSales} ventes` };
    }
    return {
        path: 'config.maxSales',
        label: 'Nombre de ventes',
        type: 'range',
        limit: [config.minSales, config.maxSales],
        step: 1,
        default: config.minSales,
        required: true,
        unit: 'ventes',
    };
}
exports.makeMaxSales = makeMaxSales;
