"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const config_1 = require("./config");
function default_1(context) {
    const graphs = new Map();
    const updates = [];
    return {
        setPeak(side, peak) {
            const graph = graphs.get(peak.index) || {};
            graph[side] = { weight: peak.weight, price: peak.price };
            graphs.set(peak.index, graph);
            updates.push(peak.index);
        },
        setWeight(side, index, weight) {
            const graph = graphs.get(index);
            if (!graph || !graph[side])
                return;
            graph[side].weight = weight;
            graphs.set(index, graph);
            updates.push(index);
        },
        setLine(side, index, line) {
            const graph = graphs.get(index);
            if (!graph || !graph[side])
                return;
            graph[side].line = line;
            graphs.set(index, graph);
            updates.push(index);
        },
        async commit(index) {
            const limit = index - config_1.bufferSize;
            for (const index of updates.splice(0, updates.length)) {
                const graph = graphs.get(index);
                if (!graph)
                    continue;
                await context.setGraph({ period: context.period, index }, graph);
            }
            for (const index of graphs.keys()) {
                if (index < limit)
                    graphs.delete(index);
            }
        },
    };
}
exports.default = default_1;
