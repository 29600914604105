"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const range_1 = require("../range");
function default_1(candles, vars, targets) {
    let values = [];
    for (const candle of candles) {
        if (candle.shape.high > 0)
            values.push(candle.shape.high);
        if (candle.shape.low > 0)
            values.push(candle.shape.low);
    }
    for (const item of vars.filter((item) => item.unit === 'price')) {
        // values = values.concat(item.values.filter((value) => value > 0));
    }
    for (const target of targets) {
        // if (target.limit) values.push(target.limit);
    }
    return {
        type: 'price',
        ...(0, range_1.makeRange)({ min: Math.min(...values), max: Math.max(...values) }),
    };
}
exports.default = default_1;
