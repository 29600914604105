"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const macros_1 = require("./macros");
const actions = {
    buy: 'Achat',
    sell: 'Vente',
};
function default_1(data, builder) {
    for (const item of data.model.deciders) {
        const indicator = builder.enter({ type: 'dir', key: item.key, name: item.name, info: item.info });
        for (const key of Object.keys(actions)) {
            indicator.push({
                key: key,
                type: 'res',
                name: actions[key],
                format: 'boolean',
                values: data.rounds.map((round) => round.deciders[`${item.key}.${key}`]),
            });
        }
        if (data.setup.flags.vars) {
            (0, macros_1.buildVars)(data, indicator, item, ['deciders', item.key]);
            (0, macros_1.buildDeps)(data, indicator, item, ['deciders', item.key]);
        }
    }
}
exports.default = default_1;
