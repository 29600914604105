"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const values = {
    analyzer: 500,
    currency: 500,
};
function default_1(model) {
    return values[model] || 700;
}
exports.default = default_1;
