"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return {
        label: 'envelope',
        title: 'Enveloppe du cycle',
        code: [
            `env.envelope.amount.total = number;`,
            "// volume total",
            `env.envelope.amount.locked = number;`,
            "// volume en cours d'utilisation",
            `env.envelope.amount.used = number;`,
            "// volume utilisé",
            `env.envelope.amount.available = number;`,
            "// volume disponible",
            ``,
            `env.envelope.slices.total = number;`,
            "// nombre de tranches total",
            `env.envelope.slices.locked = number;`,
            "// nombre de tranches en cours d'utilisation",
            `env.envelope.slices.used = number;`,
            "// nombre de tranches utilisées",
            `env.envelope.slices.available = number;`,
            "// nombre de tranches disponibles",
        ],
    };
}
exports.default = default_1;
