"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const styles_1 = require("@mui/styles");
// import DateFnsUtils from '@date-io/date-fns';
// import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
const styles = (theme) => ({
    container: {
        display: 'flex',
        alignItems: 'stretch',
        position: 'relative',
        flexGrow: 1,
        justifyContent: 'center',
    },
    input: {
        flexGrow: 1,
        borderWidth: 0,
        textAlign: 'center',
        background: 'transparent',
        outline: 'none',
        fontSize: '1.2rem',
        fontFamily: 'inherit',
        padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1.5)} ${theme.spacing(1)}`,
        cursor: 'pointer',
        color: theme.palette.text.primary,
    },
    popover: {
        padding: theme.spacing(2),
        overflow: 'hidden',
    },
});
function Component(props) {
    const anchor = (0, react_1.useRef)(null);
    const [open, setOpen] = (0, react_1.useState)(false);
    return (react_1.default.createElement("div", { className: props.classes.container },
        react_1.default.createElement("input", { ref: anchor, id: `form:${props.specs.path}`, disabled: props.specs.disabled, className: props.classes.input, value: props.value || '', style: { color: props.value || 'transparent' }, autoComplete: "off", onFocus: () => props.onFocus(), onBlur: () => props.onBlur(), onClick: () => setOpen(true), readOnly: true })));
}
Component.displayName = 'base.form.field.body.input';
exports.default = (0, styles_1.withStyles)(styles)(Component);
