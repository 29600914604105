"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sizeColumns = exports.sizeTable = exports.normalize = void 0;
const lodash_1 = require("lodash");
const sizes = { button: 50, small: 90, medium: 150, large: 200 };
const ratios = { button: 0, small: 1, medium: 2, large: 3 };
function normalize(columns) {
    return columns.map((column) => {
        let width = column.width;
        if (!width) {
            switch (column.format) {
                case 'boolean':
                case 'time':
                    width = 'small';
                    break;
                default:
                    width = 'medium';
            }
        }
        return Object.assign({}, column, { width });
    });
}
exports.normalize = normalize;
function sizeTable(columns, width) {
    return Math.max(width, (0, lodash_1.sum)(columns.map((column) => sizes[column.width])));
}
exports.sizeTable = sizeTable;
function sizeColumns(columns, width) {
    const unit = width / (0, lodash_1.sum)(columns.map((column) => ratios[column.width]));
    return columns.map((column) => ({ min: sizes[column.width], ratio: Math.round(ratios[column.width] * unit) }));
}
exports.sizeColumns = sizeColumns;
