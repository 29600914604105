"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const utils_1 = require("./utils");
function default_1(buffer, side) {
    const peaks = [];
    const slopes = [];
    return function (peak, spread) {
        peaks.push(peak);
        if (peaks.length > 1) {
            slopes.push((0, utils_1.link)(peaks[peaks.length - 2], peaks[peaks.length - 1]));
        }
        if (slopes.length < 2) {
            return;
        }
        if (!(0, utils_1.match)(slopes, spread)) {
            peaks.splice(0, peaks.length - 2);
            slopes.splice(0, slopes.length - 1);
            return;
        }
        const start = peaks[0];
        const line = (0, utils_1.merge)(peaks);
        if (line) {
            buffer.setLine(side, start.index, line);
        }
    };
}
exports.default = default_1;
