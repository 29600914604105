"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.admin = exports.write = exports.read = exports.base = exports.candle = exports.ticker = void 0;
const ticker1 = ['last', 'open', 'low', 'high', 'base', 'quote', 'previousClose'];
const ticker2 = ['askPrice', 'bidPrice', 'askVolume', 'bidVolume'];
const ticker3 = ['change', 'percentage', 'average', 'vwap'];
exports.ticker = [...ticker1, ...ticker2, ...ticker3];
exports.candle = ['open', 'close', 'low', 'high', 'volume', 'trades'];
exports.base = ['has', 'get', 'list', 'count', 'getLimit', 'setLimit'];
exports.read = ['getPeriod', 'startPeriod', 'endPeriod'];
exports.write = ['set', 'reset', 'setPeriod'];
exports.admin = ['assign', 'commit', 'dump'];
