"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(data, builder) {
    builder.push({
        key: 'currency',
        type: 'dat',
        name: 'Monnaie',
        format: 'label',
        values: data.rounds.map((round) => !!round.env?.blocking?.currency),
        access: `env.blocking?.currency`,
    });
    builder.push({
        key: 'round',
        type: 'dat',
        name: 'Round',
        format: 'number.integer',
        values: data.rounds.map((round) => !!round.env?.blocking?.round),
        access: `env.blocking?.round`,
    });
    builder.push({
        key: 'needed',
        type: 'dat',
        name: 'Volume manquant',
        format: 'number.crypto',
        values: data.rounds.map((round) => !!round.env?.blocking?.needed),
        access: `env.blocking?.needed`,
    });
}
exports.default = default_1;
