"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return {
        name: 'Arrondi inférieur',
        info: 'Retourne un nombre arrondi inférieur',
        args: [{ key: 'value', name: 'Nombre en entrée', type: 'number' }],
        result: { type: 'number' },
        call(value) {
            return Math.floor(value) || 0;
        },
    };
}
exports.default = default_1;
