"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function Component(props) {
    const columns = [
        { label: 'Nom', value: 'name', order: 'asc', width: 'large', search: true },
        { label: 'Clé', value: 'key', order: 'asc', width: 'small', search: true },
        { label: 'Base', value: 'config.base', order: 'asc', width: 'small' },
        { label: 'Entier', value: 'config.integer', format: 'boolean', filter: true, width: 'small' },
    ];
    return props.render({
        columns,
        tags: false,
        type: props.query.kind === 'conf' ? 'board' : 'table',
        order: { index: 0, direction: 'asc' },
    });
}
Component.displayName = 'crud.list.specs.parameter';
exports.default = Component;
