"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const utils_1 = require("./utils");
function default_1(state, env) {
    return {
        has(index = 0) {
            return state.data.length > (0, utils_1.makeNatural)(index);
        },
        get(index = 0) {
            const key = (0, utils_1.makeNatural)(index);
            if (key >= state.limit) {
                state.limit = key + 1;
            }
            if (state.data.length < key + 1) {
                return env.base;
            }
            return state.data[key];
        },
        list(length, index = 0) {
            const start = (0, utils_1.makeNatural)(index);
            const size = (0, utils_1.makeNatural)(length);
            const end = start + size;
            if (end >= state.limit) {
                state.limit = end + 1;
            }
            return state.data.slice(start, end);
        },
        count() {
            return state.data.length;
        },
        getLimit() {
            return state.limit;
        },
        setLimit(limit) {
            state.limit = (0, utils_1.makeNatural)(limit) || 1;
        },
    };
}
exports.default = default_1;
