"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_utils_1 = require("@brc/core-utils");
function default_1(rounds) {
    const orders = [];
    for (const [index, round] of rounds.entries()) {
        for (const order of round.orders || []) {
            const query = makeQuery(order);
            const asset = makeAsset(order);
            if (!order || !query) {
                continue;
            }
            orders.push({
                time: index,
                side: query.type,
                status: order.status,
                amounts: asset ? makeAmounts(query, asset) : undefined,
                price: asset ? asset.price : query.limit || query.pivot,
            });
        }
    }
    return orders;
}
exports.default = default_1;
function makeQuery(order) {
    return order && order.status !== 'open' && order.status !== 'skipped'
        ? order
        : undefined;
}
function makeAsset(order) {
    return order && order.status === 'closed' ? order : undefined;
}
function makeAmounts(query, asset) {
    const { base, quote } = (0, core_utils_1.decodeSymbol)(query.symbol);
    const invested = query.type === 'purchase' ? quote : base;
    const received = query.type === 'purchase' ? base : quote;
    return {
        envelope: { volume: query.envelope, currency: invested },
        filled: { volume: asset.filled, currency: received },
        cost: { volume: asset.cost, currency: invested },
        fee: { volume: asset.fee.volume, currency: asset.fee.currency },
    };
}
