"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeRange = exports.makeTop = void 0;
const r1 = 500;
const r2 = 10;
const r3 = 0.0001;
function makeTop(value) {
    if (value === 0 || !isFinite(value)) {
        return 0;
    }
    const log = Math.log10(value);
    const exp = log > 0 ? Math.floor(log) : log < 0 ? Math.ceil(log) : log;
    const base = Math.ceil(value / Math.pow(10, exp));
    return base * Math.pow(10, exp);
}
exports.makeTop = makeTop;
function makeRange(range) {
    const spread = makeTop(range.max - range.min);
    if (spread === 0) {
        return { min: range.min * 0.9, max: range.max * 1.1 };
    }
    const unit = spread / r1;
    const low = Math.floor(Math.floor((range.min * (1 - r3)) / unit) / r2) * r2;
    const high = Math.ceil(Math.ceil((range.max * (1 + r3)) / unit) / r2) * r2;
    return { min: low * unit, max: high * unit };
}
exports.makeRange = makeRange;
