"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function default_1(field, a, b) {
    switch (field.type) {
        case 'checks':
        case 'tags': {
            a = a || [];
            b = b || [];
            return a.length === b.length && (0, lodash_1.difference)(a, b).length === 0;
        }
        default:
            return a === b;
    }
}
exports.default = default_1;
