"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const styles_1 = require("@mui/styles");
const views_base_1 = require("@brc/views-base");
const styles = (theme) => ({});
function Component(props) {
    const [state, setState] = (0, react_1.useState)({
        order: { index: 0, direction: props.side === 'bids' ? 'desc' : 'asc' },
        filter: {},
    });
    if (!props.details) {
        return react_1.default.createElement(views_base_1.Center, { text: "Chargement ..." });
    }
    const items = props.side === 'bids' ? props.details.bids : props.details.asks;
    const name = props.side === 'bids' ? 'BID' : 'ASK';
    return (react_1.default.createElement(views_base_1.Table, { items: items, state: state, onState: setState, defaults: { color: (item) => (item.wall ? 'error' : undefined) }, columns: [
            { label: `Prix ${name}`, value: 'price', format: 'number.crypto', align: 'right', order: 'desc' },
            { label: `Volume ${name}`, value: 'volume', format: 'number.crypto', align: 'right', order: 'asc' },
            { label: 'Pourcent', value: 'percent', format: 'number.percent', align: 'right', order: 'asc' },
            { label: 'Total', value: 'total', format: 'number.crypto', align: 'right', order: 'asc' },
            { label: 'Cumulé', value: 'cumulative', format: 'number.crypto', align: 'right', order: 'asc' },
        ] }));
}
Component.displayName = 'macro.market.book';
exports.default = (0, styles_1.withStyles)(styles)(Component);
