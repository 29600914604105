"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const names = {
    last: 'Last price',
    change: '24h Change',
    open: '24h Open',
    low: '24h Low',
    high: '24h High',
    previousClose: '24h Close',
    base: 'Vol Base',
    quote: 'Vol Quote',
    vwap: 'Vol Average price',
    askPrice: 'Ask Price',
    askVolume: 'Ask Volume',
    bidPrice: 'Bid Price',
    bidVolume: 'Bid Volume',
};
function default_1(data, builder) {
    for (const key of Object.keys(names)) {
        builder.push({
            key,
            type: 'dat',
            name: names[key],
            format: 'number.crypto',
            values: data.rounds.map(round => round.ticker[key]),
            access: `main.ticker.${key}.get()`,
        });
    }
}
exports.default = default_1;
