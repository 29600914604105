"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const uf = new Intl.NumberFormat('fr-FR', { maximumSignificantDigits: 7 });
const kf = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0 });
const mf = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0 });
function default_1(value) {
    if (value > 1e9) {
        return `${mf.format(value / 1e6)} M`;
    }
    if (value > 1e6) {
        return `${kf.format(value / 1e3)} K`;
    }
    return uf.format(value);
}
exports.default = default_1;
