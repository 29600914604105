"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function default_1(threads) {
    const days = {};
    for (const thread of threads) {
        const date = thread.creation.slice(0, 10);
        if (!days[date])
            days[date] = [];
        days[date].push(thread);
    }
    const records = [];
    for (const date of Object.keys(days)) {
        records.push(makeRecord(date, days[date]));
    }
    return (0, lodash_1.orderBy)(records, 'date', 'desc');
}
exports.default = default_1;
function makeRecord(date, threads) {
    const profit = {}; // mergeSessionProfits(threads);
    // todo: merge threads profits
    return {
        date,
        profit: profit.quote?.percent || 0,
        threads: threads.length,
        purchases: makeSum(threads, 'state.stats.purchases.successes'),
        sales: makeSum(threads, 'state.stats.sales.successes'),
        failures: makeSum(threads, 'state.stats.purchases.failures') + makeSum(threads, 'state.stats.sales.failures'),
        snowball: profit.wallet?.snowball || 0,
        reserve: profit.wallet?.reserve || 0,
        treasure: profit.wallet?.treasure || 0,
    };
}
function makeSum(threads, path) {
    let result = 0;
    for (const thread of threads) {
        result += (0, lodash_1.get)(thread, path, 0);
    }
    return result;
}
