"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makePath = exports.makeActions = exports.makeQuery = void 0;
function makeQuery(props) {
    return {
        model: props.model,
        query: props.query,
        filter: props.filter,
        search: props.search,
    };
}
exports.makeQuery = makeQuery;
function makeActions(props) {
    return {
        open: props.open,
        update: props.update,
        clone: props.clone,
        delete: props.delete,
    };
}
exports.makeActions = makeActions;
function makePath(query) {
    const path = ['list', query.model];
    for (const key of Object.keys(query.filter || {})) {
        path.push(`${key}=${String(query.filter[key])}`);
    }
    for (const key of Object.keys(query.query || {})) {
        path.push(`${key}=${String(query.query[key])}`);
    }
    return path;
}
exports.makePath = makePath;
