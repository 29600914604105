"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(fields) {
    return cleanList(fields);
}
exports.default = default_1;
function cleanList(fields) {
    return fields
        .map((field) => cleanItem(field))
        .filter((field) => !(field.kind === 'group' && !field.fields.length));
    // if (results.length === 1 && results[0].kind === 'group') {
    //     return cleanList(results[0].fields.map((field) => ({ ...field, name: field.name })));
    // }
    //
    // return results;
}
function cleanItem(field) {
    return field.kind === 'group' ? { ...field, fields: cleanList(field.fields) } : field;
}
