"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function Component(props) {
    const columns = [
        { label: 'Nom', value: 'name', order: 'asc', search: true },
        { label: 'Email', value: 'email', order: 'asc', search: true },
    ];
    return props.render({
        columns,
        type: 'table',
        order: { index: 0, direction: 'asc' },
    });
}
Component.displayName = 'crud.list.specs.user';
exports.default = Component;
