"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeMeanPrice = void 0;
const float_1 = require("./float");
function makeMeanPrice(values) {
    let amount = 0;
    let total = 0;
    for (const value of values) {
        amount += Math.abs(value.amount);
        total += Math.abs(value.amount) * value.price;
    }
    if (!amount || !total) {
        return undefined;
    }
    return (0, float_1.fixFloat)(total / amount);
}
exports.makeMeanPrice = makeMeanPrice;
