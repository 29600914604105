"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const names = {
    target: 'Objectif (en %)',
    amount: 'Volume actuel',
    percent: 'Pourcentage actuel',
    snowball: 'BD neige',
    reserve: 'Réserve',
    treasure: 'Trésor',
};
function default_1(data, builder) {
    for (const key of Object.keys(names)) {
        builder.push({
            key: key,
            type: 'dat',
            name: names[key],
            format: 'number.crypto',
            values: data.rounds.map((round) => !!round.env?.profit[key]),
            access: `env.profit.${key}`,
        });
    }
}
exports.default = default_1;
