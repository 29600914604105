"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(logger, port) {
    return {
        watch(api, listen) {
            return port.observe({ type: 'limiter.watch', api }, (feedback) => {
                if (feedback.type === 'limiter.stats') {
                    listen(feedback.stats);
                }
            });
        },
    };
}
exports.default = default_1;
