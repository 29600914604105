"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeWarmupDelay = exports.makeRenewDelay = exports.makeMarketDelay = void 0;
function makeMarketDelay() {
    return {
        path: 'config.marketDelay',
        label: `Délai marché`,
        type: 'integer',
        limit: [250, 5000],
        default: 2000,
        required: true,
        unit: 'ms',
    };
}
exports.makeMarketDelay = makeMarketDelay;
function makeRenewDelay() {
    return {
        path: 'config.renewDelay',
        label: `Délai cycles`,
        type: 'integer',
        limit: [0, 100],
        default: 0,
        required: true,
        unit: 'rounds',
    };
}
exports.makeRenewDelay = makeRenewDelay;
function makeWarmupDelay() {
    return {
        path: 'config.warmupDelay',
        label: `Délai calculs`,
        type: 'integer',
        limit: [0, 500],
        default: 50,
        required: true,
        unit: 'rounds',
    };
}
exports.makeWarmupDelay = makeWarmupDelay;
