"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertUnits = exports.errorSource = exports.threadStatus = exports.feesType = exports.orderTiming = exports.orderStatus = exports.orderSide = exports.orderType = exports.dealStatus = exports.dealType = exports.orderFee = exports.order = exports.orderResult = exports.orderQuery = exports.orderBase = exports.deal = exports.marketPeaksProps = exports.marketPeaksTypes = exports.marketCandleProps = exports.marketCandleTypes = exports.targetLive = exports.targetPrice = exports.marketLimits = exports.marketWalls = exports.marketFees = exports.envelopePartitions = exports.envelopeGroups = exports.asset = exports.just = exports.can = exports.profit = void 0;
const core_candle_1 = require("@brc/core-candle");
// numbers
exports.profit = ['target', 'amount', 'percent', 'snowball', 'reserve', 'treasure'];
exports.can = ['open', 'phase', 'deal', 'cancel', 'buy', 'sell'];
exports.just = ['open', 'buy', 'sell', 'bought', 'sold', 'phase', 'reset'];
exports.asset = ['total', 'locks', 'reserve', 'treasure', 'available'];
exports.envelopeGroups = ['amount', 'slices'];
exports.envelopePartitions = ['total', 'locked', 'used', 'available'];
exports.marketFees = ['taker', 'maker'];
exports.marketWalls = ['ask', 'bid'];
exports.marketLimits = ['purchase', 'sale'];
exports.targetPrice = ['base', 'limit', 'profit', 'stopLoss', 'takeProfit'];
exports.targetLive = ['profit', 'distance'];
exports.marketCandleTypes = ['candle', ...Object.keys(core_candle_1.periods).map(period => `candle${period}`)];
exports.marketCandleProps = ['index', 'updated'];
exports.marketPeaksTypes = ['min', 'max'];
exports.marketPeaksProps = ['price', 'weight', 'age'];
// mixed
exports.deal = ['type', 'since', 'envelope', 'status', 'base', 'quote'];
exports.orderBase = ['id', 'timeout', 'start', 'duration', 'status'];
exports.orderQuery = ['type', 'symbol', 'envelope', 'pivot', 'limit', 'stop', 'leverage', 'timing'];
exports.orderResult = ['stamp', 'price', 'filled', 'cost'];
exports.order = [...exports.orderBase, ...exports.orderQuery, ...exports.orderResult];
exports.orderFee = ['currency', 'volume', 'rate'];
// enums
exports.dealType = ['purchase', 'sale'];
exports.dealStatus = ['ongoing', 'success', 'failure'];
exports.orderType = ['purchase', 'sale'];
exports.orderSide = ['buy', 'sell'];
exports.orderStatus = ['open', 'closed', 'canceled', 'timeout', 'error'];
exports.orderTiming = ['GTC', 'IOC', 'FOK'];
exports.feesType = ['none', 'base', 'quote', 'other', 'mixed'];
exports.threadStatus = ['pending', 'open', 'buying', 'selling', 'done', 'failed'];
exports.errorSource = ['network', 'credentials', 'request', 'response', 'unknown'];
exports.convertUnits = ['second', 'seconds', 'minute', 'minutes', 'hour', 'hours', 'day', 'days'];
