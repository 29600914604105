"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return {
        label: 'wallet',
        items: [
            { label: 'base', title: 'Monnaie BASE du wallet', code: makeCode('base') },
            { label: 'quote', title: 'Monnaie QUOTE du wallet', code: makeCode('quote') },
        ],
    };
}
exports.default = default_1;
function makeCode(key) {
    return [
        `env.wallet.${key} = {`,
        '    total: number, // avoir total',
        '    locks: number, // avoir verrouillé',
        '    reserve: number, // volume de réserve',
        '    treasure: number, // volume du trésor',
        '    available: number, // avoir disponible',
        '};',
    ];
}
