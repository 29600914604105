"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.format = exports.normalize = void 0;
function normalize(data) {
    let config = data.config || {};
    if (data.type === 'analyzer') {
        const loopPeriod = config.loopPeriod;
        config = { ...config, loopPeriod: loopPeriod ? loopPeriod / 2 : undefined };
    }
    return {
        name: data.name,
        key: data.key,
        config,
        tags: data.tags,
        info: data.info,
    };
}
exports.normalize = normalize;
function format(props, data) {
    const result = {
        ...data,
        code: props.data.code || '',
        config: formatConfig(props, data),
    };
    if (props.type !== 'update') {
        result.type = props.data.type;
    }
    return result;
}
exports.format = format;
function formatConfig(props, data) {
    switch (props.data.type) {
        case 'analyzer':
            const config = { ...(props.data.config || {}), ...(data.config || {}) };
            if (config.loopPeriod) {
                config.loopPeriod = config.loopPeriod * 2;
            }
            if (config.manyPurchases) {
                const min = config.minPurchases || 2;
                const max = config.maxPurchases || 20;
                config.minPurchases = Math.min(min, max);
                config.maxPurchases = Math.max(min, max);
            }
            if (config.manySales) {
                const min = config.minSales || 2;
                const max = config.maxSales || 20;
                config.minSales = Math.min(min, max);
                config.maxSales = Math.max(min, max);
            }
            return config;
        case 'indicator':
            return data.config || {};
        case 'pattern':
            return { priceTrend: 0, volumeTrend: 0, ...(data.config || {}) };
        default:
            return {};
    }
}
