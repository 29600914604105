"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return {
        label: 'round',
        title: 'Données sur le round',
        code: [
            'env.round.index = number;',
            '// numéro du round courant (commence par 1)',
            'env.round.duration = number;',
            "// durée d'un round en secondes",
            'env.round.convert = (amount: number, unit: string) => number;',
            '// converti une durée en nombre de rounds',
            '// unité disponibles: second, minute, hour, day',
            '// (les pluriels sont également acceptés)',
        ],
    };
}
exports.default = default_1;
