"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const views_base_1 = require("@brc/views-base");
const views_store_1 = require("@brc/views-store");
const lodash_1 = require("lodash");
const utils_1 = require("./utils");
function Component(props) {
    const token = (0, views_store_1.useAuthToken)();
    const [candles, setCandles] = (0, react_1.useState)();
    const effect = () => {
        fetch((0, utils_1.makeUrl)(token, props.currency, props.years))
            .then((result) => result.json())
            .then((result) => setCandles((0, utils_1.makeCandles)(result.data)));
    };
    (0, react_1.useEffect)(effect, [props.currency, props.years, token]);
    if (!candles) {
        return react_1.default.createElement(views_base_1.Center, { text: "Chargement en cours..." });
    }
    return props.render(candles.map((candle, index) => ({
        index,
        stamp: candle.stamp,
        candle: { shape: (0, lodash_1.omit)(candle, 'stamp'), patterns: [], indicators: {} },
    })));
}
Component.displayName = 'data.build.currency.loader';
exports.default = Component;
