"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(session) {
    const config = session.config;
    const currency = config.startCurrency;
    const props = [
        { label: 'Profit cible', value: config.targetProfit, type: 'number.percent', column: 1 },
        { label: 'Achats max', value: config.maxPurchases, column: 1 },
        { label: 'Ventes max', value: config.maxSales, column: 1 },
        { label: 'Envelope', value: config.startEnvelope, type: 'number.crypto', unit: currency, column: 2 },
        { label: 'Période', value: config.loopPeriod / 2, unit: 'secondes', column: 2 },
    ];
    if (session.start) {
        props.push({ label: 'Démarrage', value: session.start, type: 'moment', column: 2 });
    }
    if (session.stop) {
        props.push({ label: 'Arrêt', value: session.stop, type: 'moment', column: 2 });
    }
    return { props };
}
exports.default = default_1;
