"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(input) {
    const rounds = [];
    const items = {};
    for (const pattern of input.model.patterns) {
        items[pattern.key] = { name: pattern.name, price: 0, volume: 0 };
    }
    for (const round of input.rounds) {
        rounds.push({
            id: round.index,
            items: round.candle.patterns.map((key) => items[key]).filter(v => !!v),
            price: round.candle.trend?.price || 0,
            volume: round.candle.trend?.volume || 0,
        });
    }
    return { enabled: input.setup.flags.patterns, rounds };
}
exports.default = default_1;
