"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(model) {
    const shape = [
        'candle.shape.open',
        'candle.shape.low',
        'candle.shape.high',
        'candle.shape.close',
        'candle.shape.volume',
    ];
    return {
        selection: [...shape, ...model.indicators.map((indicator) => `candle.indicators.${indicator.key}.result`)],
    };
}
exports.default = default_1;
