"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildShape = exports.buildMap = void 0;
function buildMap(keys, type) {
    const props = {};
    for (const key of keys) {
        props[key] = Array.isArray(key) ? buildMap(key, type) : type;
    }
    return buildShape(props);
}
exports.buildMap = buildMap;
function buildShape(props, readonly) {
    const realProps = {};
    const optionals = [];
    for (const key of Object.keys(props)) {
        let propKey = key;
        if (key.endsWith('?')) {
            propKey = key.slice(0, key.length - 1);
            optionals.push(propKey);
        }
        realProps[propKey] = props[key];
    }
    return {
        kind: 'shape',
        props: realProps,
        optionals,
        readonly,
    };
}
exports.buildShape = buildShape;
