"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const names = {
    website: 'Site web',
    twitter: 'Compte Twitter',
    blog: 'Blog officiel',
    chat: 'Chat officiel',
    fee: 'Commissions',
    reddit: 'Page Reddit',
    message_board: 'Forum officiel',
    announcement: 'Annonces officielles',
    source_code: 'Code source',
    technical_doc: 'Documentation',
    explorer: 'Explorateur',
};
const icons = {
    website: 'link.home',
    twitter: 'link.twitter',
    chat: 'link.forum',
    reddit: 'link.reddit',
    message_board: 'link.forum',
    announcement: 'link.forum',
    source_code: 'link.github',
    technical_doc: 'link.docs',
};
function default_1(data) {
    const items = [];
    for (const key of Object.keys(data)) {
        for (const link of data[key]) {
            const url = new URL(link);
            items.push({
                icon: icons[key] || 'link.default',
                name: names[key] || 'Lien',
                host: url.hostname,
                path: url.pathname.length <= 32 ? url.pathname : url.pathname.slice(0, 30) + '...',
                link,
            });
        }
    }
    return items;
}
exports.default = default_1;
