"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(tree, selection) {
    const taken = [];
    function explore(node, parents) {
        if (node.kind === 'group') {
            for (const child of node.fields) {
                explore(child, [...parents, node]);
            }
        }
        if (node.kind === 'value' && selection.includes(node.key)) {
            taken.push({ value: node, parents });
        }
    }
    for (const node of tree) {
        explore(node, []);
    }
    const results = [];
    for (const key of selection) {
        const result = taken.filter((item) => item.value.key === key)[0];
        if (result) {
            results.push(result);
        }
    }
    return results;
}
exports.default = default_1;
