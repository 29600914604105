"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeCandles = exports.makeDate = exports.makeUrl = void 0;
function makeUrl(token, currency, years) {
    const url = 'https://web-api.coinmarketcap.com/v1/cryptocurrency/ohlcv/historical' +
        `?convert=EUR&symbol=${currency}&time_start=${makeDate(years * 365 + 1)}&time_end=${makeDate(1)}`;
    return `${process.env.brc_api}/proxy?url=${encodeURIComponent(url)}&token=${token}`;
}
exports.makeUrl = makeUrl;
function makeDate(days) {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date.toISOString().substr(0, 10);
}
exports.makeDate = makeDate;
function makeCandles(data) {
    const candles = [];
    for (const item of data?.quotes || []) {
        const values = item?.quote?.EUR;
        if (values) {
            candles.push({
                open: values.open,
                close: values.close,
                high: values.high,
                low: values.low,
                volume: values.volume,
                stamp: new Date(values.timestamp).getTime(),
            });
        }
    }
    return candles;
}
exports.makeCandles = makeCandles;
