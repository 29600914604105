"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function makeFallback(type) {
    switch (type.kind) {
        case 'array':
            return [];
        case 'enum':
            return '';
        case 'func': {
            const fallback = type.result.type ? makeFallback(type.result.type) : null;
            return type.result.async ? Promise.resolve(fallback) : fallback;
        }
        case 'record':
            return {};
        case 'scalar':
            switch (type.type) {
                case 'boolean':
                    return false;
                case 'number':
                    return 0;
                case 'string':
                    return '';
                case 'any':
                    return {};
            }
            return null;
        case 'shape': {
            const fallback = {};
            for (const key of Object.keys(type.props)) {
                fallback[key] = makeFallback(type.props[key]);
            }
            return fallback;
        }
        case 'vars':
            return {};
        default:
            return null;
    }
}
exports.default = makeFallback;
