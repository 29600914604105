"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(rounds) {
    const targets = [];
    let cursor;
    for (const [time, round] of rounds.entries()) {
        if (!round.target || !round.target.limit || !round.target.profit) {
            continue;
        }
        if (cursor) {
            if (cursor.profit === round.target.profit && cursor.limit === round.target.limit) {
                cursor.end = time;
                continue;
            }
            targets.push(cursor);
            cursor = undefined;
        }
        cursor = { start: time, end: time, profit: round.target.profit, limit: round.target.limit };
    }
    if (cursor) {
        targets.push(cursor);
    }
    return targets;
}
exports.default = default_1;
