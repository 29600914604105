"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(rounds) {
    const asks = { type: 'bids', tops: [], walls: [] };
    const bids = { type: 'bids', tops: [], walls: [] };
    for (const round of rounds) {
        if (round.asks && round.asks.length) {
            asks.tops.push(round.asks[0].price);
            asks.walls.push(makeWall(round.asks));
        }
        if (round.bids && round.bids.length) {
            bids.tops.push(round.bids[0].price);
            bids.walls.push(makeWall(round.bids));
        }
    }
    return { asks, bids };
}
exports.default = default_1;
function makeWall(points) {
    let wall = false;
    for (const point of points) {
        if (wall)
            return point.price;
        if (point.wall)
            wall = true;
    }
    return points[points.length - 1].price;
}
