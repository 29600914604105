"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const utils_1 = require("./utils");
function Component(props) {
    const columns = [
        { label: 'Commande', width: 'large', value: 'type', format: 'label', filter: true, search: true },
        { label: 'Date', value: 'creation', format: 'moment' },
        (0, utils_1.makeOwner)(),
    ];
    return props.render({
        columns,
        type: 'table',
        order: { index: 0, direction: 'asc' },
    });
}
Component.displayName = 'crud.list.specs.command';
exports.default = Component;
