"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vars_1 = require("@brc/core-script/vars");
function default_1() {
    return function (complete) {
        const completions = [];
        for (const key of vars_1.keys.candle) {
            for (const method of vars_1.keys.base) {
                completions.push({ value: `main.${key}.${method}()`, meta: 'function' });
            }
        }
        complete(completions);
    };
}
exports.default = default_1;
