"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vars_1 = require("@brc/core-script/vars");
function default_1(model, id) {
    return function (complete) {
        return model.list('parameter', { kind: 'var', strategy: id }, (parameters) => {
            const completions = [];
            for (const parameter of parameters) {
                for (const method of [...vars_1.keys.base, ...vars_1.keys.read, ...vars_1.keys.write]) {
                    completions.push({ value: `vars.${parameter.key}.${method}()`, meta: 'function' });
                }
            }
            complete(completions);
        });
    };
}
exports.default = default_1;
