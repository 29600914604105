"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.merge = exports.match = exports.link = void 0;
const lodash_1 = require("lodash");
const config_1 = require("../config");
function link(a, b) {
    return a.index === b.index ? 0 : (b.price - a.price) / (b.index - a.index);
}
exports.link = link;
function match(slopes, spread) {
    if (slopes.length < 2) {
        return false;
    }
    const max = Math.max(...slopes);
    const min = Math.min(...slopes);
    return max - min <= spread * config_1.slopeTolerance;
}
exports.match = match;
function merge(peaks) {
    if (peaks.length < 2) {
        return undefined;
    }
    const start = peaks[0];
    const end = peaks[peaks.length - 1];
    const slopes = [];
    for (const next of peaks.slice(1)) {
        slopes.push(link(start, next));
    }
    return {
        slope: (0, lodash_1.sum)(slopes) / slopes.length,
        length: end.index - start.index,
        spread: end.price - start.price,
        peaks: peaks.length,
    };
}
exports.merge = merge;
