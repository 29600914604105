"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return function (complete) {
        complete([
            { value: 'main.thread.open()', meta: 'function' },
            { value: 'main.thread.buy()', meta: 'function' },
            { value: 'main.thread.sell()', meta: 'function' },
            { value: 'main.thread.jump()', meta: 'function' },
            { value: 'main.thread.stop()', meta: 'function' },
            { value: 'main.thread.kill()', meta: 'function' },
        ]);
    };
}
exports.default = default_1;
