"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(library) {
    const lib = {};
    for (const key of Object.keys(library)) {
        lib[key] = compile(library[key]);
    }
    return lib;
}
exports.default = default_1;
function compile(pkg) {
    const result = {};
    for (const key of Object.keys(pkg)) {
        result[key] = pkg[key].call;
    }
    return result;
}
