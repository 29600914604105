"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.copyBindings = exports.copyParameters = void 0;
const lodash_1 = require("lodash");
async function copyParameters(args) {
    const parameters = await new Promise((resolve) => args.model.list('parameter', { [args.source.model]: args.source.id }, (parameters) => resolve(parameters)));
    for (const parameter of parameters) {
        const copy = { ...parameter, [args.target.model]: args.target.id };
        copy.config = (0, lodash_1.pick)(copy.config, ['base', 'integer', 'graph']);
        await args.model.create(clean(copy));
    }
}
exports.copyParameters = copyParameters;
async function copyBindings(args) {
    const bindings = await new Promise((resolve) => args.model.list('binding', { parent: args.source.id }, (bindings) => resolve(bindings)));
    for (const binding of bindings) {
        const copy = { ...binding, parent: args.target.id };
        await args.model.create(clean(copy, ['types']));
    }
}
exports.copyBindings = copyBindings;
function clean(entity, extra = []) {
    return (0, lodash_1.omit)(entity, ['id', 'creation', 'update', 'deletion', ...extra]);
}
