"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(side, values, weight, last) {
    if (values.length < weight * 2 + 1) {
        return false;
    }
    const current = values[weight];
    for (let offset = last + 1; offset <= weight; offset++) {
        if (!compare(side, current, values[weight - offset]) || !compare(side, current, values[weight + offset])) {
            return false;
        }
    }
    return true;
}
exports.default = default_1;
function compare(side, value, other) {
    switch (side) {
        case 'low':
            return value < other;
        case 'high':
            return value > other;
    }
}
