"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(strategy) {
    switch (strategy.type) {
        case 'analyzer': {
            const config = strategy.config;
            const manyPurchases = config.manyPurchases
                ? `Entre ${config.minPurchases} et ${config.maxPurchases} achats`
                : 'Non';
            const manySales = config.manySales
                ? `Entre ${config.minSales} et ${config.maxSales} ventes`
                : 'Non';
            const props = [
                { label: 'Période', value: config.loopPeriod / 2, type: 'number', unit: 'secondes' },
                { label: 'Achats multiples', value: manyPurchases },
                { label: 'Ventes multiples', value: manySales },
                { label: 'Créé le', value: strategy.creation, type: 'moment.date' },
            ];
            return { props };
        }
        case 'indicator': {
            const config = strategy.config;
            let props = [
                { label: 'Unité', value: config.graph?.unit, type: 'graph.unit', column: 1 },
                { label: 'Affichage', value: config.graph?.zone, type: 'graph.zone', column: 1 },
            ];
            if (config.graph?.zone && config.graph?.zone !== 'none') {
                props = props.concat([
                    { label: 'Style', value: config.graph?.stroke, type: 'graph.stroke', column: 2 },
                    { label: 'Couleur', value: config.graph?.color, type: 'graph.color', column: 2 },
                ]);
            }
            return { props };
        }
        default:
            return { props: [{ label: 'Créé le', value: strategy.creation, type: 'moment.date' }] };
    }
}
exports.default = default_1;
