"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const styles_1 = require("@mui/styles");
const styles = (theme) => ({
    container: {
        position: 'relative',
        flexGrow: 1,
        overflow: 'hidden',
    },
    wrapper: (props) => ({
        position: 'absolute',
        top: 0,
        right: props.state.free ? undefined : 0,
        left: props.state.free ? -props.state.offset : undefined,
        bottom: 0,
        width: `${props.time.scroll.inner}px`,
        display: 'flex',
        alignItems: 'stretch',
    }),
});
function Component(props) {
    const [move, setMove] = (0, react_1.useState)();
    const ref = (0, react_1.useRef)();
    function setOffset(offset) {
        props.onState(offset >= props.time.scroll.move
            ? { offset: undefined, free: false }
            : { offset: Math.max(0, offset), free: true });
    }
    function getOffset() {
        return props.state.free ? props.state.offset || 0 : props.time.scroll.move;
    }
    return (react_1.default.createElement("div", { className: props.classes.container },
        react_1.default.createElement("div", { ref: ref, style: { cursor: move ? 'grabbing' : 'default' }, className: props.classes.wrapper, onMouseDown: (event) => setMove({ start: event.clientX, offset: getOffset() }), onMouseUp: () => setMove(undefined), onMouseLeave: () => {
                setMove(undefined);
                props.onState({ time: undefined });
            }, onWheel: (event) => setOffset(getOffset() + event.deltaX + event.deltaY), onMouseMove: (event) => {
                if (move) {
                    setOffset(move.offset + (move.start - event.clientX));
                }
                const node = ref.current;
                const rect = node ? node.getBoundingClientRect() : undefined;
                const left = rect ? rect.left : 0;
                const time = props.time.getIndex(event.clientX - left);
                if (time !== props.state.time) {
                    props.onState({ time });
                }
            } }, props.content || null)));
}
Component.displayName = 'data.graph.show.base.layer.main';
exports.default = (0, styles_1.withStyles)(styles)(Component);
