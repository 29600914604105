"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(path, level) {
    const base = [
        `${path}.has(): boolean`,
        '// teste si la valeur courante existe',
        `${path}.has(offset: number): boolean`,
        '// teste si la valeur historique existe',
        `${path}.get(): number`,
        '// retourne la valeur courante',
        `${path}.get(offset: number): number`,
        '// retourne une valeur historique',
        `${path}.list(length: number): number[]`,
        '// retourne une liste de valeurs (la plus récente en premier)',
        `${path}.list(length: number, offset: number): number[]`,
        '// retourne une liste de valeurs (la plus récente en premier)',
        `${path}.count(): number`,
        '// retourne la taille de l\'historique',
        `${path}.getLimit(): number`,
        '// retourne la limite d\'historique',
        `${path}.setLimit(limit: number): void`,
        '// change la limite d\'historique',
    ];
    const read = [
        `${path}.getPeriod(): number`,
        '// retourne la période d\'historisation',
        `${path}.startPeriod(): boolean`,
        '// teste si le round courant débute la période d\'historisation',
        `${path}.endPeriod(): boolean`,
        '// teste si le round courant termine la période d\'historisation',
    ];
    const write = [
        `${path}.set(value: number): void`,
        '// assigne une valeur courante',
        `${path}.setPeriod(period: number): void`,
        '// change la période d\'historisation',
        `${path}.reset(): void`,
        '// réinitialise la variable',
    ];
    switch (level) {
        case 'base':
            return base;
        case 'read':
            return [...base, ...read];
        case 'write':
            return [...base, ...read, ...write];
    }
}
exports.default = default_1;
