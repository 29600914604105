"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSessionState = exports.useLocalState = void 0;
const react_1 = require("react");
function useStoredState(storage, key, init) {
    const [data, setData] = (0, react_1.useState)(storage.get(key) || init);
    const assign = (data) => {
        storage.set(key, data);
        setData(data);
    };
    return [data, assign];
}
function useLocalState(key, init) {
    const storage = {
        has: (key) => localStorage.getItem(key.join(':')) !== null,
        set: (key, data) => localStorage.setItem(key.join(':'), JSON.stringify(data)),
        del: (key) => localStorage.removeItem(key.join(':')),
        get: (key) => {
            const data = localStorage.getItem(key.join(':'));
            return data === null ? null : JSON.parse(data);
        },
    };
    return useStoredState(storage, key, init);
}
exports.useLocalState = useLocalState;
function useSessionState(key, init) {
    const storage = {
        has: (key) => sessionStorage.getItem(key.join(':')) !== null,
        set: (key, data) => sessionStorage.setItem(key.join(':'), JSON.stringify(data)),
        del: (key) => sessionStorage.removeItem(key.join(':')),
        get: (key) => {
            const data = sessionStorage.getItem(key.join(':'));
            return data === null ? null : JSON.parse(data);
        },
    };
    return useStoredState(storage, key, init);
}
exports.useSessionState = useSessionState;
