"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const views_base_1 = require("@brc/views-base");
const views_store_1 = require("@brc/views-store");
function Component(props) {
    const wallet = (0, views_store_1.useWalletService)();
    const [disabled, setDisabled] = (0, react_1.useState)(false);
    return (react_1.default.createElement(views_base_1.Button, { variant: "contained", size: "large", label: "Sync", icon: "action.sync", disabled: disabled, onClick: async () => {
            setDisabled(true);
            for (const account of props.accounts) {
                await wallet.sync(account.id);
            }
            setDisabled(false);
        } }));
}
Component.displayName = 'macro.account.sync';
exports.default = Component;
