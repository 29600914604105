"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
async function default_1(context, state, key, shapes) {
    if (!context.strategies[key.period]) {
        throw new Error(`Tried to compute unknown "${key.period}" candle.`);
    }
    const last = state.lasts[key.period];
    if (last && last !== key.index - 1) {
        context.logger.warn('candle.compute.index', { key, last });
    }
    const periodData = state.data[key.period];
    const periodGraph = state.graph[key.period];
    for (const [index, shape] of shapes.entries()) {
        await periodData.compute(key.index + index, shape);
        await periodGraph.compute(key.index + index, shape);
    }
    state.lasts[key.period] = key.index + shapes.length - 1;
}
exports.default = default_1;
