"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const make_1 = require("./make");
const SHORT = 5e3;
const LONG = 30e3;
function default_1(driver, channel) {
    const messages = {};
    const shortTimers = {};
    const longTimers = {};
    function commit(key) {
        const batch = (0, make_1.makeBatch)(messages[key] || []);
        if (batch)
            channel.send(batch);
        if (shortTimers[key])
            clearTimeout(shortTimers[key]);
        if (longTimers[key])
            clearTimeout(longTimers[key]);
        delete messages[key];
        delete shortTimers[key];
        delete longTimers[key];
    }
    driver.listen((message) => {
        const key = (0, make_1.makeKey)(message);
        if (!messages[key]) {
            messages[key] = [];
            longTimers[key] = setTimeout(() => commit(key), LONG);
        }
        if (shortTimers[key]) {
            clearTimeout(shortTimers[key]);
        }
        shortTimers[key] = setTimeout(() => commit(key), SHORT);
        messages[key].push(message);
    });
}
exports.default = default_1;
