"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(builder, library) {
    const props = {};
    for (const key of Object.keys(library)) {
        props[key] = compile(builder, library[key]);
    }
    return builder.shape(props, true);
}
exports.default = default_1;
function compile(builder, pkg) {
    const props = {};
    for (const key of Object.keys(pkg)) {
        const func = pkg[key];
        const args = [];
        for (const arg of func.args) {
            args.push({ key: arg.key, type: makeType(builder, arg), optional: arg.default !== undefined });
        }
        props[key] = builder.func(args, { type: makeType(builder, func.result), async: func.async });
    }
    return builder.shape(props);
}
function makeType(builder, type) {
    switch (type.type) {
        case 'boolean':
            return builder.boolean();
        case 'number':
            return builder.number();
        case 'number[]':
            return builder.array(builder.number());
        case 'object':
            return makeProps(builder, type.props);
        case 'object[]':
            return builder.array(makeProps(builder, type.props));
        case 'enum':
            return builder.enum(type.values);
    }
}
function makeProps(builder, props) {
    const shape = {};
    for (const prop of props) {
        const key = prop.default !== undefined ? `${prop.key}?` : prop.key;
        switch (prop.type) {
            case 'boolean':
                shape[key] = builder.boolean();
                break;
            case 'number':
                shape[key] = builder.number();
                break;
            case 'number[]':
                shape[key] = builder.array(builder.number());
                break;
        }
    }
    return builder.shape(shape);
}
