"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(logger, port) {
    return {
        async create(data) {
            const command = { type: 'model.create', data };
            const feedback = await port.execute(command);
            if (feedback.type !== 'model.created') {
                logger.alert('model.feedback.invalid', { command, feedback });
                throw new Error(`Invalid feedback for entity create.`);
            }
            return feedback.data;
        },
        async update(data) {
            const command = { type: 'model.update', data };
            const feedback = await port.execute(command);
            if (feedback.type !== 'model.updated') {
                logger.alert('model.feedback.invalid', { command, feedback });
                throw new Error(`Invalid feedback for entity update.`);
            }
            return feedback.data;
        },
        async delete(model, id) {
            const command = { type: 'model.delete', model, id };
            const feedback = await port.execute(command);
            if (feedback.type !== 'model.deleted') {
                logger.alert('model.feedback.invalid', { command, feedback });
                throw new Error(`Invalid feedback for entity delete.`);
            }
        },
    };
}
exports.default = default_1;
