"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(configs, patterns) {
    const trend = { price: 0, volume: 0 };
    for (const pattern of patterns) {
        const config = configs[pattern];
        if (!config) {
            continue;
        }
        trend.price += config.priceTrend || 0;
        trend.volume += config.volumeTrend || 0;
    }
    return trend;
}
exports.default = default_1;
