"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return {
        label: 'can',
        title: 'Actions possibles',
        code: [
            `env.can.open = number; // 0: faux, 1: vrai`,
            "// si vrai, l'ouverture du thread est possible",
            `env.can.phase = number; // 0: faux, 1: vrai`,
            "// si vrai, le cycle peut passer à la phase suivante",
            `env.can.deal = number; // 0: faux, 1: vrai`,
            "// si vrai, un passage d\'ordre est autorisé",
            `env.can.cancel = number; // 0: faux, 1: vrai`,
            "// si vrai, une annulation d\'ordre peut être demandée",
            `env.can.buy = number; // 0: faux, 1: vrai`,
            "// si vrai, une demande d'achat est possible",
            `env.can.sell = number; // 0: faux, 1: vrai`,
            '// si vrai, une demande de vente est possible',
        ],
    };
}
exports.default = default_1;
