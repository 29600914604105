"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const styles_1 = require("@mui/styles");
const react_sortable_hoc_1 = require("react-sortable-hoc");
const utils_1 = require("./utils");
const styles = (theme) => ({
    container: (props) => ({
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing((props.spacing || 0) / 2),
        alignContent: 'flex-start',
    }),
    element: (props) => ({
        width: `${100 / (props.columns || 1)}%`,
        padding: theme.spacing((props.spacing || 0) / 2),
        cursor: 'grab',
    }),
    helper: {
        cursor: 'grab',
        pointerEvents: 'auto !important',
        zIndex: 10000,
    },
});
function Component(props) {
    const [items, setItems] = (0, react_1.useState)((0, utils_1.wrap)(props.items));
    (0, react_1.useEffect)(() => setItems((0, utils_1.wrap)(props.items)), [props.items]);
    const Element = (0, react_sortable_hoc_1.SortableElement)((args) => (react_1.default.createElement("div", { className: props.classes.element }, props.render(args.value.data, args.value.index))));
    const classes = [props.classes.container];
    if (props.className)
        classes.push(props.className);
    const Container = (0, react_sortable_hoc_1.SortableContainer)((args) => (react_1.default.createElement("div", { className: classes.join(' ') }, args.items.map((item) => (react_1.default.createElement(Element, { key: item.index, value: item, index: item.index }))))));
    return (react_1.default.createElement(Container, { axis: "xy", items: items, helperClass: props.classes.helper, distance: 10, onSortEnd: (args) => {
            const moved = (0, utils_1.move)(items, args.oldIndex, args.newIndex);
            setItems(moved);
            props.onMoved((0, utils_1.unwrap)(moved));
        } }));
}
Component.displayName = 'base.sortable';
exports.default = (0, styles_1.withStyles)(styles)(Component);
