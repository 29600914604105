"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.labels = exports.choices = void 0;
const choices = [
    { label: 'Prix', value: 'price' },
    { label: 'Volume', value: 'volume' },
    { label: 'Pourcent', value: 'percent' },
    { label: 'Zone', value: 'zone' },
    { label: 'Booléen', value: 'boolean' },
    { label: 'Ratio', value: 'ratio' },
    { label: 'Autre', value: 'other' },
];
exports.choices = choices;
const labels = {};
exports.labels = labels;
for (const choice of choices) {
    labels[choice.value] = choice.label;
}
