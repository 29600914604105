"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return {
        label: 'deal ?',
        title: 'Deal en cours (optionnel)',
        code: [
            `env.deal = undefined | {`,
            "    type: 'purchase' | 'sale',",
            '    since: number, // numéro du round de départ',
            '    symbol: string, // paire BASE/QUOTE',
            "    envelope: number, // volume de l'enveloppe",
            "    status: 'ongoing' | 'success' | 'failure',",
            "    filled?: number, // Volume de remplissage (si succès)",
            "    cost?: number, // Volume du coût (si succès)",
            "    price?: number, // Prix moyen du deal (si succès)",
            '};',
        ],
    };
}
exports.default = default_1;
