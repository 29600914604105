"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeBalances = void 0;
function mergeBalances(balances) {
    const result = {};
    for (const balance of balances) {
        for (const currency of Object.keys(balance)) {
            result[currency] = (result[currency] || 0) + (balance[currency] || 0);
        }
    }
    return result;
}
exports.mergeBalances = mergeBalances;
