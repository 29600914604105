"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const models = {
    account: 'ce compte',
    parameter: 'ce paramètre',
    recorder: 'cette capture',
    session: "cette session d'analyse",
    strategy: 'cette stratégie',
    user: 'cet utilisateur',
};
const strategies = {
    analyzer: "cette stratégie d'analyse",
    buyer: "cette stratégie d'achat",
    computer: 'ce module de calcul',
    decider: 'cette stratégie de décision',
    indicator: 'cet indicateur de chandelle',
    pattern: 'ce motif de chandelles',
    seller: 'cette stratégie de vente',
    jumper: 'cette stratégie de jump',
};
function default_1(entity) {
    let that = models[entity.model] || 'cette entité';
    if (entity.model === 'strategy') {
        that = strategies[entity.type];
    }
    return `Sûr de vouloir supprimer ${that} ?`;
}
exports.default = default_1;
