"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (theme) => ({
    container: (props) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: '1 1 0',
        '&:hover': { background: props.paper ? theme.palette.action.hover : theme.palette.background.paper },
        cursor: 'pointer',
        padding: `0 ${theme.spacing(1)}`,
        whiteSpace: 'nowrap',
        background: props.paper ? 'transparent' : theme.palette.background.header,
        marginRight: props.paper ? 0 : theme.spacing(0.5),
        '&:last-child': { marginRight: 0 },
        ...(props.paper
            ? {
                '&:first-child': {
                    borderTopLeftRadius: `${theme.shape.borderRadius}px`,
                    borderBottomLeftRadius: `${theme.shape.borderRadius}px`,
                },
                '&:last-child': {
                    borderTopRightRadius: `${theme.shape.borderRadius}px`,
                    borderBottomRightRadius: `${theme.shape.borderRadius}px`,
                },
            }
            : {
                borderRadius: `${props.height / 2}px`,
            }),
    }),
    icon: {
        padding: `0 ${theme.spacing(0.5)}`,
        display: 'flex',
        alignItems: 'center',
    },
    label: {
        padding: `0 ${theme.spacing(0.5)}`,
    },
    count: {
        padding: `0 ${theme.spacing(0.5)}`,
        opacity: 0.5,
    },
    active: (props) => ({
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': { background: theme.palette.primary.main },
    }),
});
