"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function default_1() {
    return {
        name: 'Somme',
        info: "Retourne la somme d'une liste de nombres",
        args: [{ key: 'values', name: 'Liste de nombres', type: 'number[]' }],
        result: { type: 'number' },
        call(values) {
            return (0, lodash_1.sum)(values) || 0;
        },
    };
}
exports.default = default_1;
