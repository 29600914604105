"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultColor = exports.zoneLabels = exports.zoneChoices = exports.unitLabels = exports.unitChoices = exports.strokeLabels = exports.strokeChoices = void 0;
const stroke_1 = require("./stroke");
Object.defineProperty(exports, "strokeChoices", { enumerable: true, get: function () { return stroke_1.choices; } });
Object.defineProperty(exports, "strokeLabels", { enumerable: true, get: function () { return stroke_1.labels; } });
const unit_1 = require("./unit");
Object.defineProperty(exports, "unitChoices", { enumerable: true, get: function () { return unit_1.choices; } });
Object.defineProperty(exports, "unitLabels", { enumerable: true, get: function () { return unit_1.labels; } });
const zone_1 = require("./zone");
Object.defineProperty(exports, "zoneChoices", { enumerable: true, get: function () { return zone_1.choices; } });
Object.defineProperty(exports, "zoneLabels", { enumerable: true, get: function () { return zone_1.labels; } });
const defaultColor = '#888888';
exports.defaultColor = defaultColor;
