"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const keys = __importStar(require("./keys"));
function default_1(builder) {
    const props = {
        price: builder.number(),
        limits: builder.map(keys.marketLimits, builder.number()),
        fees: builder.map(keys.marketFees, builder.number()),
        walls: builder.map(keys.marketWalls, builder.number()),
    };
    for (const candleType of keys.marketCandleTypes) {
        const candleProps = {};
        for (const candleProp of keys.marketCandleProps) {
            candleProps[candleProp] = builder.number();
            for (const peakType of keys.marketPeaksTypes) {
                const peakProps = {};
                for (const peakProp of keys.marketPeaksProps) {
                    peakProps[peakProp] = builder.number();
                }
                candleProps[peakType] = builder.shape(peakProps);
            }
        }
        props[candleType] = builder.shape(candleProps);
    }
    return builder.shape(props);
}
exports.default = default_1;
