"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return {
        name: 'Logaritme',
        info: "Retourne le log d'un nombre",
        args: [
            { key: 'value', name: 'Nombre en entrée', type: 'number' },
            { key: 'base', name: 'Base', type: 'number', default: 10 },
        ],
        result: { type: 'number' },
        call(value, base) {
            return (Math.log(value) / Math.log(value || 10)) || 0;
        },
    };
}
exports.default = default_1;
