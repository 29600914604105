"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const amountNames = {
    total: 'Env. totale',
    used: 'Env. utilisée',
    locked: 'Env. en cours',
    available: 'Env. disponible',
};
const slicesNames = {
    total: 'Tr. totales',
    used: 'Tr. utilisées',
    locked: 'Tr. en cours',
    available: 'Tr. disponibles',
};
function default_1(data, builder) {
    for (const key of Object.keys(amountNames)) {
        builder.push({
            key: `envelope.amount.${key}`,
            type: 'dat',
            name: `${amountNames[key]}`,
            format: 'number.crypto',
            values: data.rounds.map((round) => round.env?.envelope?.amount?.[key]),
            access: `env.envelope?.amount?.${key}`,
        });
    }
    for (const key of Object.keys(slicesNames)) {
        builder.push({
            key: `envelope.slices.${key}`,
            type: 'dat',
            name: `${slicesNames[key]}`,
            format: 'number.integer',
            values: data.rounds.map((round) => round.env?.envelope?.slices?.[key]),
            access: `env.envelope?.slices?.${key}`,
        });
    }
}
exports.default = default_1;
