"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(field, value) {
    if (value === null || value === undefined) {
        value = field.default;
    }
    switch (field.type) {
        case 'string':
        case 'text':
        case 'key':
        case 'radios':
        case 'select':
        case 'color':
            return value || '';
        case 'integer':
        case 'float':
        case 'range':
            return typeof value === 'number' ? String(value) : '';
        case 'checks':
        case 'tags':
            return value;
        case 'switch':
            return !!value;
        case 'date':
            return value || null;
    }
    return '';
}
exports.default = default_1;
