"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(zoom) {
    switch (zoom) {
        case 1:
            return { width: 3, space: 0 };
        case 2:
            return { width: 5, space: 1 };
        case 3:
            return { width: 7, space: 1 };
    }
}
exports.default = default_1;
