"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const views_base_1 = require("@brc/views-base");
const views_crud_1 = require("@brc/views-crud");
const lodash_1 = require("lodash");
function Component(props) {
    const columns = props.columns;
    const [state, setState] = (0, react_1.useState)({
        filter: props.defaults?.filter || {},
        order: props.defaults?.order || { index: 0, direction: 'asc' },
    });
    if (props.owner) {
        columns.push({
            label: 'Responsable',
            value: (item) => (0, lodash_1.get)(item, 'owner', ''),
            format: (id) => react_1.default.createElement(views_crud_1.Value, { model: "user", id: id }),
        });
    }
    if (props.clone) {
        const trigger = { variant: 'cell', color: 'primary', icon: 'action.clone' };
        columns.push({
            label: '',
            value: (item) => props.clone(item, trigger),
            format: (element) => element,
            width: 'button',
        });
    }
    if (props.delete) {
        const trigger = { variant: 'cell', color: 'secondary', icon: 'action.delete' };
        columns.push({
            label: '',
            value: (item) => props.delete(item, trigger),
            format: (element) => element,
            width: 'button',
        });
    }
    return (react_1.default.createElement(views_base_1.Table, { items: props.items, columns: columns, empty: props.empty, onClick: props.onOpen ? (item) => props.onOpen(item) : undefined, defaults: (0, lodash_1.omit)(props.defaults, ['filter', 'order']), state: state, onState: setState }));
}
Component.displayName = 'macro.common.table';
exports.default = Component;
