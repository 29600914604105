"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeBatch = exports.makeKey = void 0;
const keys_1 = require("../../keys");
function makeKey(message) {
    return `${message.type}:${message.key}@${makeUnit(message.unit)}`;
}
exports.makeKey = makeKey;
function makeBatch(messages) {
    if (!messages.length) {
        return undefined;
    }
    return {
        unit: messages[0].unit,
        type: messages[0].type,
        key: messages[0].key,
        time: messages[0].time,
        events: messages.map((message) => ({ time: message.time, data: message.data })),
    };
}
exports.makeBatch = makeBatch;
function makeUnit(unit) {
    switch (unit.app) {
        case 'console':
        case 'client':
        case 'store':
            return unit.app;
        default:
            return (0, keys_1.flatten)(unit);
    }
}
