"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(theme, status, action) {
    if (action) {
        switch (action.type) {
            case 'open':
                return theme.palette.success.main;
            case 'buy':
                return theme.palette.primary.main;
            case 'sell':
                return theme.palette.secondary.main;
            case 'cancel':
                return theme.palette.error.main;
        }
    }
    switch (status) {
        case 'pending':
            return 'transparent';
        case 'open':
            return theme.palette.success.main;
        case 'done':
            return 'transparent';
    }
}
exports.default = default_1;
