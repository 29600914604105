"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(data, size) {
    const width = data.time.size.width;
    const space = data.time.size.space;
    const outer = size.width.main;
    const inner = data.time.count * (width + space) - space;
    const move = Math.max(0, inner - outer);
    const getStart = (index) => index * (width + space);
    return {
        width,
        space,
        scroll: { outer, inner, move },
        getStart,
        getEnd: (index) => getStart(index) + width,
        getCenter: (index) => getStart(index) + width / 2,
        getIndex: (x) => Math.floor(x / (width + space)),
    };
}
exports.default = default_1;
