"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(market) {
    const details = market.details;
    const props = [
        { label: 'Commission "taker"', value: details.fees.taker * 100, type: 'number', unit: '%', column: 1 },
        { label: 'Commission "maker"', value: details.fees.maker * 100, type: 'number', unit: '%', column: 1 },
        { label: "Enveloppe d'achat min", value: details.limits.quote.min, type: 'number', column: 2 },
        { label: 'Enveloppe de vente min', value: details.limits.base.min, type: 'number', column: 2 },
    ];
    return { props };
}
exports.default = default_1;
