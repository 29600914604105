"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const size = 5;
const names = {
    price: 'Prix',
    volume: 'Vol Base',
    total: 'Vol Quote',
    cumulative: 'Vol cumulé',
    percent: 'Pourcentage',
    wall: 'Mur détecté',
};
const formats = {
    price: 'number.crypto',
    volume: 'number.crypto',
    total: 'number.crypto',
    percent: 'number.percent',
    cumulative: 'number.crypto',
    wall: 'boolean',
};
function default_1(data, builder) {
    for (let index = 0; index < size; index++) {
        build(builder.enter({ type: 'dir', key: `ask${index + 1}`, name: `Ask ${index + 1}` }), data.rounds.map((round) => (round.asks || [])[index]));
    }
    for (let index = 0; index < size; index++) {
        build(builder.enter({ type: 'dir', key: `bid${index + 1}`, name: `Bid ${index + 1}` }), data.rounds.map((round) => (round.bids || [])[index]));
    }
}
exports.default = default_1;
function build(builder, points) {
    for (const key of Object.keys(names)) {
        builder.push({
            key,
            type: 'dat',
            name: names[key],
            format: formats[key],
            values: points.map((point) => (point ? point[key] : undefined)),
        });
    }
}
