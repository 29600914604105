"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(session, graph) {
    function makeProps(children) {
        return (children || []).map((child) => ({
            label: child.binding.key,
            type: 'label',
            value: child.strategy.name,
        }));
    }
    return {
        label: 'Stratégie',
        value: { type: 'label', data: graph.strategy.name, status: graph.strategy.check?.status || 'pending' },
        body: [
            { props: [{ label: 'Analyse', type: 'label', value: graph.strategy.name }] },
            { title: 'Décisions', props: makeProps(graph.deciders) },
            { title: 'Achats', props: makeProps(graph.buyers) },
            { title: 'Ventes', props: makeProps(graph.sellers) },
            { title: 'Jumps', props: makeProps(graph.jumpers) },
        ],
    };
}
exports.default = default_1;
