"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vars_1 = require("@brc/core-script/vars");
function default_1(rounds, period) {
    const index = rounds.length ? rounds[0].key.index : 0;
    const state = {};
    for (const key of rounds.length ? Object.keys(rounds[0].indicators) : []) {
        state[key] = { limit: 1, period: 1, data: rounds.map(round => round.indicators[key] || 0) };
    }
    state.priceTrend = { limit: 1, period: 1, data: rounds.map(round => round.trend.price || 0) };
    state.volumeTrend = { limit: 1, period: 1, data: rounds.map(round => round.trend.volume || 0) };
    for (const key of vars_1.keys.candle) {
        state[key] = { limit: 1, period: 1, data: rounds.map(round => round.shape[key] || 0) };
    }
    return (0, vars_1.makeLib)({
        path: ['main', `candle${period}`],
        keys: Object.keys(state),
        getRound: () => index,
        state,
    });
}
exports.default = default_1;
