"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const macros_1 = require("../macros");
function default_1(data, builder) {
    for (const item of data.model.indicators) {
        const indicator = builder.enter({ type: 'dir', key: item.key, name: item.name, info: item.info });
        indicator.push({
            key: 'result',
            type: 'res',
            name: item.name,
            format: 'number',
            values: data.rounds.map((round) => (round.candle.indicators || {})[item.key]),
            access: `main.candle.${item.key}.get()`,
        });
        if (data.setup.flags.vars) {
            (0, macros_1.buildVars)(data, indicator, item, ['indicators', item.key]);
            (0, macros_1.buildDeps)(data, indicator, item, ['indicators', item.key]);
        }
    }
}
exports.default = default_1;
