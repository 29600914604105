"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.format = void 0;
function format(props, children, data) {
    const result = { ...data };
    if (!result.key) {
        for (const child of children) {
            if (data.child === child.id) {
                result.key = child.key;
            }
        }
    }
    if (!result.config) {
        result.config = { parameters: {} };
    }
    if (props.type !== 'update') {
        result.parent = props.data.parent;
    }
    return result;
}
exports.format = format;
