"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeVars = exports.makeDeps = void 0;
function makeDeps(children) {
    return children.map((child) => ({
        key: child.binding.key,
        name: child.strategy.name,
        info: child.strategy.info,
        vars: makeVars(child.parameters || []),
        deps: makeDeps(child.computers || []),
    }));
}
exports.makeDeps = makeDeps;
function makeVars(params) {
    return params
        .filter((param) => param.kind === 'var')
        .map((param) => ({
        key: param.key,
        name: param.name,
        info: param.info,
        unit: param.config.graph?.unit || 'other',
        zone: param.config.graph?.zone || 'none',
        color: param.config.graph?.color,
        stroke: param.config.graph?.stroke,
    }));
}
exports.makeVars = makeVars;
