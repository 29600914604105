"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(model, id) {
    return function (complete) {
        return model.list('parameter', { kind: 'conf', strategy: id }, (parameters) => {
            const completions = [];
            for (const parameter of parameters) {
                completions.push({ value: `conf.${parameter.key}`, meta: 'number' });
            }
            complete(completions);
        });
    };
}
exports.default = default_1;
