"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(scale, graph, height, slices) {
    const outer = height;
    const inner = outer;
    const move = Math.max(0, inner - outer);
    const exp = Math.pow(10, scale.exp || 0);
    const range = (scale.max - scale.min) / exp;
    const ratio = -inner / range;
    const offset = inner - (scale.min * ratio) / exp;
    return {
        scroll: { outer, inner, move },
        transform: `matrix(1, 0, 0, ${ratio}, 0, ${offset})`,
        labels: makeLabels(scale, slices),
        getPosition: (value) => value * ratio + offset,
        getValue: (y) => exp * (y - offset) / ratio,
        exp: scale.exp,
    };
}
exports.default = default_1;
function makeLabels(scale, slices) {
    const size = (scale.max - scale.min) / slices;
    const labels = [];
    for (let index = 1; index < slices; index++) {
        labels.push(scale.min + index * size);
    }
    return labels;
}
