"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return {
        name: 'Ecart',
        info: "Retourne l'écart entre 2 nombres",
        args: [
            { key: 'a', name: 'Nombre 1', type: 'number' },
            { key: 'b', name: 'Nombre 2', type: 'number' },
        ],
        result: { type: 'number' },
        call(a, b) {
            return Math.abs(a - b) || 0;
        },
    };
}
exports.default = default_1;
