"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(listen, time) {
    const state = { done: false, data: undefined };
    setTimeout(() => {
        state.done = true;
        if (state.data)
            listen(state.data);
    }, time);
    return (data) => {
        if (state.done)
            listen(data);
        else
            state.data = data;
    };
}
exports.default = default_1;
