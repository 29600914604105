"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const types = [
    'manager.process.data',
    'manager.server.data',
    'market.data',
    'server.postgres.execute.done',
    'server.redis.read.done',
    'server.redis.write.done',
    'server.redis.delete.done',
    'server.redis.list.done',
    'server.redis.assign.done',
    'server.redis.push.done',
    'session.round',
];
const events = [
    'analyzer.round',
    'logger.message',
    'manager.server',
    'manager.process',
    'manager.ping',
    'manager.pong',
    'market.data',
    'model.updated',
    'model.created',
    'model.reload',
    'model.entity',
];
function default_1(message) {
    if (message.type !== 'debug') {
        return false;
    }
    const ft = (0, lodash_1.get)(message, 'data.feedback.type');
    const mft = (0, lodash_1.get)(message, 'data.message.feedback.type');
    const send = ['server.bus.send', 'server.socket.broadcast'].includes(message.key);
    return (types.includes(message.key) ||
        types.includes(ft) ||
        types.includes(mft) ||
        (send && events.includes((0, lodash_1.get)(message, 'data.type'))));
}
exports.default = default_1;
