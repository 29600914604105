"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(data, builder) {
    builder.push({
        key: 'type',
        type: 'dat',
        name: 'Type',
        format: 'label',
        values: data.rounds.map((round) => !!round.env?.deal?.type),
        access: `env.deal?.type`,
    });
    builder.push({
        key: 'since',
        type: 'dat',
        name: 'Round',
        format: 'number.integer',
        values: data.rounds.map((round) => !!round.env?.deal?.since),
        access: `env.deal?.since`,
    });
    builder.push({
        key: 'symbol',
        type: 'dat',
        name: 'Symbol',
        format: 'label',
        values: data.rounds.map((round) => !!round.env?.deal?.symbol),
        access: `env.deal?.symbol`,
    });
    builder.push({
        key: 'envelope',
        type: 'dat',
        name: 'Enveloppe',
        format: 'number.crypto',
        values: data.rounds.map((round) => !!round.env?.deal?.envelope),
        access: `env.deal?.envelope`,
    });
    builder.push({
        key: 'status',
        type: 'dat',
        name: 'Statut',
        format: 'label',
        values: data.rounds.map((round) => !!round.env?.deal?.status),
        access: `env.deal?.status`,
    });
    builder.push({
        key: 'filled',
        type: 'dat',
        name: 'Remplissage',
        format: 'number.crypto',
        values: data.rounds.map((round) => !!round.env?.deal?.filled),
        access: `env.deal?.filled`,
    });
    builder.push({
        key: 'cost',
        type: 'dat',
        name: 'Coût',
        format: 'number.crypto',
        values: data.rounds.map((round) => !!round.env?.deal?.cost),
        access: `env.deal?.cost`,
    });
    builder.push({
        key: 'price',
        type: 'dat',
        name: 'Prix',
        format: 'number.crypto',
        values: data.rounds.map((round) => !!round.env?.deal?.price),
        access: `env.deal?.price`,
    });
}
exports.default = default_1;
