"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(currency) {
    const details = currency.details || {};
    let props = [{ label: 'Utilisée', value: currency.used, type: 'boolean' }];
    if (currency.used) {
        props = props.concat([
            { label: 'Envelope', value: currency.config.envelope, type: 'number' },
            { label: 'Min change', value: currency.config.minChange, type: 'number' },
        ]);
    }
    if (details) {
        props = props.concat([
            { label: 'Marchés', value: details.markets, type: 'number' },
            { label: 'Rang CCM', value: details.rank, type: 'number' },
            { label: 'Capitalisation', value: details.capitalization, type: 'number', unit: '€' },
            { label: 'Volume total', value: details.coins?.total, type: 'number' },
            { label: 'Volume en circulation', value: details.coins?.circulating, type: 'number' },
            { label: 'Volume maximum', value: details.coins?.max, type: 'number' },
            { label: 'Volume 24h', value: details.volumes?.day, type: 'number' },
            { label: 'Changement 1h', value: details.changes?.hour, type: 'number', unit: '%' },
            { label: 'Changement 24h', value: details.changes?.day, type: 'number', unit: '%' },
            { label: 'Changement 7j', value: details.changes?.week, type: 'number', unit: '%' },
            { label: 'Prix unitaire', value: currency.price, type: 'number', unit: '€' },
            { label: 'Monnaie parente', value: details.parent?.currency },
        ]);
    }
    if (details.fcas) {
        props = props.concat([
            { label: 'Score FCAS', value: details.fcas.score, type: 'number' },
            { label: 'Grade FCAS', value: details.fcas.grade, type: 'label' },
        ]);
    }
    return { props, links: details.links || {} };
}
exports.default = default_1;
