"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(points) {
    let base = 0;
    let quote = 0;
    let weight = 0;
    for (const point of points) {
        base += point.volume;
        quote += point.volume / point.price;
        weight += point.volume * point.price;
    }
    return {
        pivot: base > 0 ? weight / base : 0,
        limit: points.length ? points[points.length - 1].price : 0,
        base,
        quote,
        size: points.length,
    };
}
exports.default = default_1;
