"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const graph_1 = require("@brc/infra-core/graph");
function default_1(data) {
    return [
        { path: 'config.graph.unit', label: 'Unité', type: 'select', choices: graph_1.unitChoices, default: 'other' },
        { path: 'config.graph.zone', label: 'Affichage', type: 'select', choices: graph_1.zoneChoices, default: 'none' },
        { path: 'config.graph.stroke', label: 'Style', type: 'select', choices: graph_1.strokeChoices, default: 'solid' },
        { path: 'config.graph.color', label: 'Couleur', type: 'color', default: graph_1.defaultColor },
    ];
}
exports.default = default_1;
