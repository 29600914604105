"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const styles_1 = require("@mui/styles");
const styles = (theme) => ({
    line: {
        stroke: theme.palette.text.primary,
    },
});
function Component(props) {
    const [hover, setHover] = (0, react_1.useState)(false);
    let peak;
    if (props.data && props.data.high) {
        peak = props.data.high;
    }
    if (props.data && props.data.low) {
        peak = props.data.low;
    }
    if (!peak || !peak.line) {
        return null;
    }
    const a1 = {
        x: props.time.getCenter(props.index),
        y: peak.price,
    };
    const b1 = {
        x: props.time.getCenter(props.index + peak.line.length),
        y: peak.price + peak.line.slope * peak.line.length,
    };
    const a2 = {
        x: props.time.getCenter(props.index - 500),
        y: peak.price - peak.line.slope * 500,
    };
    const b2 = {
        x: props.time.getCenter(props.index + 500),
        y: peak.price + peak.line.slope * 500,
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("g", { onMouseEnter: () => setHover(true), onMouseLeave: () => setHover(false) },
            react_1.default.createElement("line", { x1: a1.x, y1: a1.y, x2: b1.x, y2: b1.y, vectorEffect: "non-scaling-stroke", className: props.classes.line, strokeWidth: 10, opacity: hover ? 0.15 : 0 }),
            react_1.default.createElement("line", { x1: a1.x, y1: a1.y, x2: b1.x, y2: b1.y, vectorEffect: "non-scaling-stroke", className: props.classes.line, strokeWidth: 1, opacity: hover ? 0.5 : 0.25 })),
        hover ? (react_1.default.createElement("g", null,
            react_1.default.createElement("line", { x1: a2.x, y1: a2.y, x2: a1.x, y2: a1.y, vectorEffect: "non-scaling-stroke", className: props.classes.line, strokeDasharray: "7,4", opacity: 0.5 }),
            react_1.default.createElement("line", { x1: b1.x, y1: b1.y, x2: b2.x, y2: b2.y, vectorEffect: "non-scaling-stroke", className: props.classes.line, strokeDasharray: "7,4", opacity: 0.5 }))) : null));
}
Component.displayName = 'data.graph.show.base.candles.line';
exports.default = (0, styles_1.withStyles)(styles)(Component);
