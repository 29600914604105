"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const utils_1 = require("./utils");
function default_1(state, env) {
    return {
        getPeriod() {
            return state.period;
        },
        startPeriod() {
            return (0, utils_1.makeTrigger)(state.period, env.getRound());
        },
        endPeriod() {
            return (0, utils_1.makeTrigger)(state.period, env.getRound() - 1);
        },
    };
}
exports.default = default_1;
