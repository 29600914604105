"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return {
        drawings: [],
        scale: {
            side: 'pv',
            time: {
                free: false,
                offset: 0,
                width: 8,
            },
            main: {
                price: { zoom: 0, offset: 0 },
                volume: { zoom: 0, offset: 0 },
            },
        },
    };
}
exports.default = default_1;
