"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return {
        label: 'profit',
        title: 'Indicateurs de profit',
        code: [
            `env.profit.target = number;`,
            '// objectif de profit en pourcent',
            `env.profit.amount = number;`,
            '// profit actuel de la session en QUOTE',
            `env.profit.percent = number;`,
            '// profit actuel de la session en pourcent',
            `env.profit.snowball = number;`,
            "// volume ajouté à l'enveloppe du prochain thread",
            `env.profit.reserve = number;`,
            '// volume placé dans la réserve',
            `env.profit.treasure = number;`,
            '// volume placé dans le trésor',
        ],
    };
}
exports.default = default_1;
