"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handle = exports.connect = void 0;
function connect(port, state, update) {
    const token = localStorage.getItem('brc:token');
    if (token) {
        update({ status: 'connecting' });
        port.execute({ type: 'auth.connect', token }).then((feedback) => {
            handle(state, feedback, update);
        });
    }
}
exports.connect = connect;
function handle(state, feedback, update) {
    switch (feedback.type) {
        case 'auth.error':
            update({ status: 'failed', user: null });
            break;
        case 'auth.open':
            localStorage.setItem('brc:token', feedback.session.token);
            update({ status: 'authenticated', user: feedback.session.user, token: feedback.session.token });
            break;
        case 'auth.close':
            update({ status: 'anonymous', user: null, token: null });
            break;
    }
}
exports.handle = handle;
