"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1() {
    return {
        name: 'Minimum',
        info: "Retourne le plus petit nombre d'une liste donée",
        args: [{ key: 'values', name: 'Liste de nombres', type: 'number[]' }],
        result: { type: 'number' },
        call(values) {
            return Math.min(...values) || 0;
        },
    };
}
exports.default = default_1;
