"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(points, limits) {
    return filterQuote(filterBase(filterPrice(filterDepth(points, limits), limits), limits), limits);
}
exports.default = default_1;
function filterDepth(points, limits) {
    return limits.minDepth || limits.maxDepth ? points.slice(limits.minDepth || 0, limits.maxDepth || 20) : points;
}
function filterPrice(points, limits) {
    return limits.minPrice || limits.maxPrice
        ? points.filter((point) => {
            const reject1 = limits.minPrice && point.price < limits.minPrice;
            const reject2 = limits.maxPrice && point.price > limits.maxPrice;
            return !reject1 && !reject2;
        })
        : points;
}
function filterBase(points, limits) {
    return limits.maxBase ? filterVolume(points, limits.maxBase, (point) => point.volume) : points;
}
function filterQuote(points, limits) {
    return limits.maxQuote
        ? filterVolume(points, limits.maxQuote, (point) => point.volume * point.price)
        : points;
}
function filterVolume(points, limit, convert) {
    const taken = [];
    let used = 0;
    let cumulative = 0;
    for (const point of points) {
        const size = convert(point);
        if (size + used >= limit) {
            const ratio = (limit - used) / size;
            const volume = point.volume * ratio;
            cumulative += volume;
            taken.push({
                price: point.price,
                volume,
                total: point.price * volume,
                percent: (100 * volume) / limit,
                cumulative,
                wall: point.wall,
            });
            return taken;
        }
        taken.push(point);
        used += size;
    }
    return taken;
}
