"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function Builder(fields, path) {
    return {
        enter(child) {
            const childFields = [];
            fields.push({
                kind: 'group',
                type: child.type,
                key: [...path.map((node) => node.key), child.key].join('.'),
                name: child.name,
                info: child.info,
                fields: childFields,
            });
            return Builder(childFields, [...path, { key: child.key, name: child.name }]);
        },
        push(data) {
            fields.push({
                kind: 'value',
                type: data.type,
                key: [...path.map((node) => node.key), data.key].join('.'),
                name: data.name,
                info: data.info,
                parents: path.map((node) => node.name),
                access: data.access,
                format: data.format,
                values: data.values,
            });
        },
    };
}
exports.default = Builder;
