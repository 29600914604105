"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(groups, value) {
    if (!groups.length) {
        return undefined;
    }
    const name = makeName(groups);
    switch (groups[0].name) {
        case 'Environnement':
            return `Env : ${name}`;
        case 'Order book':
            return `OB : ${name}`;
        case 'Chandelle':
            return groups.length > 1 && groups[1].name === 'Indicateurs' && value.type !== 'res'
                ? `Ind : ${name}`
                : 'Chandelle';
        case 'Décideurs':
            return `Dec : ${name}`;
    }
}
exports.default = default_1;
function makeName(groups) {
    let name = '?';
    for (const group of groups) {
        if (group.type === 'dir')
            name = group.name;
        else
            return name;
    }
    return name;
}
