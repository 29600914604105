"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trends = exports.periods = void 0;
const MIN = 60; // in seconds
const HOUR = 60 * MIN; // in seconds
exports.periods = {
    '1m': MIN,
    '5m': 5 * MIN,
    '15m': 15 * MIN,
    '30m': 30 * MIN,
    '1h': HOUR,
    '4h': 4 * HOUR,
    '1d': 24 * HOUR,
};
exports.trends = ['priceTrend', 'volumeTrend'];
