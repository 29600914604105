"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function default_1(parameters) {
    const items = [];
    for (const parameter of (0, lodash_1.orderBy)(parameters, 'name', 'asc')) {
        items.push({
            label: parameter.key,
            title: parameter.name,
            info: parameter.info,
            code: makeCode(parameter),
        });
    }
    return items;
}
exports.default = default_1;
function makeCode(parameter) {
    return [`const value = conf.${parameter.key};`];
}
